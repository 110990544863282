import React from "react";
import Masonry from "react-masonry-css";
import {
	Box,
	Card,
	CardFooter,
	IconButton,
	Image,
	Text,
	useToast,
	HStack,
	Flex,
	Stack,
	Heading,
	Container,
	Icon,
	useColorModeValue,
} from "@chakra-ui/react";
import {
	FcAssistant,
	FcCollaboration,
	FcDonate,
	FcPositiveDynamic,
	FcMoneyTransfer,
} from "react-icons/fc";
import {
	FacebookIcon,
	FacebookShareButton,
	TwitterIcon,
	TwitterShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	LineShareButton,
	LineIcon,
} from "react-share";
import { CopyIcon } from "@chakra-ui/icons";
import "../../Masonry.css";

import restaurantImage from "../../images/post/restaurant.jpg";
import hotelImage from "../../images/post/hotel.jpg";

const Post = ({ title, image, url }) => {
	const toast = useToast();

	const handleCopyLink = () => {
		navigator.clipboard.writeText(url);
		toast({
			title: "Link copied!",
			status: "success",
			duration: 2000,
			isClosable: true,
		});
	};

	return (
		<Box mb={4}>
			<Card
				border="1px"
				borderColor="gray.200"
				boxShadow="sm"
				borderRadius={"xl"}
			>
				<Image src={image} alt={title} borderTopRadius="xl" />
				<CardFooter p={3}>
					<Flex width="100%" justifyContent="center">
						<HStack spacing={4}>
							<FacebookShareButton url={url}>
								<FacebookIcon size={32} round />
							</FacebookShareButton>
							<TwitterShareButton url={url}>
								<TwitterIcon size={32} round />
							</TwitterShareButton>
							<LineShareButton url={url}>
								<LineIcon size={32} round />
							</LineShareButton>
							<LinkedinShareButton url={url}>
								<LinkedinIcon size={32} round />
							</LinkedinShareButton>
							<IconButton
								icon={<CopyIcon />}
								onClick={handleCopyLink}
								aria-label="Copy link"
								size="sm"
								variant="ghost"
							/>
						</HStack>
					</Flex>
				</CardFooter>
			</Card>
		</Box>
	);
};

// Masonry layout with react-masonry-css
const MasonryLayout = ({ posts }) => {
	const breakpointColumnsObj = {
		default: 3, // 4 columns on extra-large screens
		1400: 3, // 3 columns on large screens
		1100: 2, // 2 columns on medium screens
		700: 1, // 1 column on small screens
	};

	return (
		<Masonry
			breakpointCols={breakpointColumnsObj}
			className="masonry-grid"
			columnClassName="masonry-grid_column"
		>
			{posts.map((post, index) => (
				<Post key={index} {...post} />
			))}
		</Masonry>
	);
};

// Example usage with posts data
const Revenue = ({ ref_code }) => {
	const postsData = [
		{
			title: "sevscan Restaurant POS QR Menu",
			image: restaurantImage,
			url: `https://sevscan.com/?ref=${ref_code}`,
		},
		{
			title: "sevscan Room POS QR Menu",
			image: hotelImage,
			url: `https://sevscan.com/?ref=${ref_code}`,
		},
		// {
		// 	title: "Discover the Latest Updates 3",
		// 	image:
		// 		"https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg",
		// 	url: `https://sevscan.com/?ref=${ref_code}&post=3`,
		// },
		// {
		// 	title: "Discover the Latest Updates 4",
		// 	image:
		// 		"https://gratisography.com/wp-content/uploads/2024/03/gratisography-funflower-800x525.jpg",
		// 	url: `https://sevscan.com/?ref=${ref_code}&post=4`,
		// },
		// {
		// 	title: "Discover the Latest Updates 5",
		// 	image:
		// 		"https://letsenhance.io/static/03620c83508fc72c6d2b218c7e304ba5/11499/UpscalerAfter.jpg",
		// 	url: `https://sevscan.com/?ref=${ref_code}&post=5`,
		// },
		// {
		// 	title: "Discover the Latest Updates 6",
		// 	image: "https://th.bing.com/th/id/OIG2.9O4YqGf98tiYzjKDvg7L",
		// 	url: `https://sevscan.com/?ref=${ref_code}&post=6`,
		// },
		// {
		// 	title: "Discover the Latest Updates 7",
		// 	image:
		// 		"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBD_rOB19y9Uq-TdyNSfoZB8pTdFY5csJF5A&s",
		// 	url: `https://sevscan.com/?ref=${ref_code}&post=7`,
		// },
	];

	return (
		<>
			<Flex
				w="auto"
				justifyContent="center"
				alignItems="center"
				minH="calc(100vh - 200px)"
			>
				<Box>
					<Container maxW={"7xl"} my={{ base: 0, md: 2, xl: 8 }} p={0}>
						<Flex
							flexWrap="wrap"
							gridGap={{ base: 2, md: 6, xl: 10 }}
							justify="center"
						>
							<CardDT
								heading={"1. แชร์ลิ้งค์แนะนำ"}
								icon={<Icon as={FcAssistant} w={10} h={10} />}
								description={"นำเสนอบริการตามช่องทางต่างๆ พร้อมแนบลิ้งค์แนะนำ"}
							/>
							<CardDT
								heading={"2. มีผู้มาสมัคร"}
								icon={<Icon as={FcCollaboration} w={10} h={10} />}
								description={"จะทดลองใช้งานระบบฟรี 30 วัน"}
							/>
							<CardDT
								heading={"3. เมื่อต่ออายุ"}
								icon={<Icon as={FcDonate} w={10} h={10} />}
								description={
									<>
										ผู้แนะนำรับ{" "}
										<Text
											as="span"
											fontSize={"lg"}
											fontWeight="bold"
											color={"cyan.600"}
										>
											20%
										</Text>{" "}
										ของทุกการต่ออายุ
									</>
								}
							/>
							<CardDT
								heading={"4. ยอดสะสมครบ"}
								icon={<Icon as={FcPositiveDynamic} w={10} h={10} />}
								description={"สามารถทำการถอนหากมียอดรอถอนขั้นต่ำ 1000 บาท"}
							/>
							<CardDT
								heading={"5. ถอนเงิน"}
								icon={<Icon as={FcMoneyTransfer} w={10} h={10} />}
								description={"ระยะเวลาตรวจสอบไม่เกิน 2 - 5 วันทำการ"}
							/>
						</Flex>
					</Container>
					<Box textAlign={"center"}>
						<Text pt={4}>
							ขอบคุณสำหรับความสนใจของท่าน
							และหวังว่าท่านจะมีผู้สมัครให้ยอดรอถอนสะสมอย่างน้อย 1000
							บาทครบทุกเดือน เพื่อสร้างเป็นรายได้ประจำอีกหนึ่งช่องทาง
						</Text>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="center"
							mt={2}
						>
							<Box display={"inline-list-item"}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="1.5em"
									height="1.5em"
									viewBox="0 0 32 32"
								>
									<g fill="none">
										<path
											fill="#0074ba"
											d="M6 6c4.665-2.332 8.5.5 10 2.5c1.5-2 5.335-4.832 10-2.5c6 3 4.5 10.5 0 15c-2.196 2.196-6.063 6.063-8.891 8.214a1.76 1.76 0 0 1-2.186-.041C12.33 27.08 8.165 23.165 6 21C1.5 16.5 0 9 6 6"
										/>
										<path
											fill="#1345b7"
											d="M16 8.5v3.049c1.27-2.684 4.425-6.269 9.658-5.712c-4.51-2.03-8.195.712-9.658 2.663m-4.054-2.963C10.26 4.95 8.225 4.887 6 6C0 9 1.5 16.5 6 21c2.165 2.165 6.33 6.08 8.923 8.173a1.76 1.76 0 0 0 2.186.04q.381-.29.785-.619c-2.854-2.142-6.86-5.518-9.035-7.462c-4.957-4.43-6.61-11.814 0-14.768a9.7 9.7 0 0 1 3.087-.827"
										/>
										<ellipse
											cx="23.477"
											cy="12.594"
											fill="#5092ff"
											rx="2.836"
											ry="4.781"
											transform="rotate(30 23.477 12.594)"
										/>
									</g>
								</svg>
								<Text ml={2} pt={0.5}>
									Thank You
								</Text>
							</Box>
						</Box>
					</Box>

					<Card px={5} pt={5} pb={4} mb={6} boxShadow={"none"} display={"none"}>
						<Box>
							<MasonryLayout posts={postsData} />
						</Box>
					</Card>
				</Box>
			</Flex>
		</>
	);
};

const CardDT = ({ heading, description, icon }) => {
	return (
		<Box
			maxW={{ base: "full", md: "340px", lg: "300px" }}
			w={"full"}
			borderWidth="1px"
			borderRadius="lg"
			overflow="hidden"
			p={5}
			bgColor={"white"}
		>
			<Stack align={"start"} spacing={2}>
				<Flex
					w={16}
					h={16}
					align={"center"}
					justify={"center"}
					color={"white"}
					rounded={"full"}
					bg={useColorModeValue("gray.100", "gray.700")}
				>
					{icon}
				</Flex>
				<Box mt={2}>
					<Heading size="md">{heading}</Heading>
					<Text mt={1} fontSize={"sm"}>
						{description}
					</Text>
				</Box>
			</Stack>
		</Box>
	);
};

export default Revenue;
