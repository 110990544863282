import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const TeamContext = createContext();

export const TeamProvider = ({ children }) => {
	const [teamUser, setTeamUser] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const team_data = JSON.parse(localStorage.getItem("team_data"));
		if (team_data !== null) {
			setTeamUser(team_data);
		}
	}, []);

	const TeamLogout = () => {
		localStorage.removeItem("team_data");
		setTeamUser(null);
		navigate("/team/login");
	};

	return (
		<TeamContext.Provider value={{ teamUser, setTeamUser, TeamLogout }}>
			{children}
		</TeamContext.Provider>
	);
};

export default TeamContext;
