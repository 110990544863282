import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
	EmbeddedCheckoutProvider,
	EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import {
	Flex,
	Spacer,
	Text,
	Stack,
	Select,
	Box,
	Icon,
	Button,
	Container,
	Card,
} from "@chakra-ui/react";
import { WarningIcon, CheckCircleIcon } from "@chakra-ui/icons";
import { useAuth } from "../../hooks/AuthProvider";
import { API_BASE_URL } from "../../config/apiConfig";
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router

const stripePromise = loadStripe(
	"pk_live_51PCwhF2LZUy9EH8lQ8kyvSs1nn5DtqN3FWbpCQyDso1Y7jr0IZJjB2IyU8U5foWpN3zXn2goVj0fT7QijD2sHLDX00MKjFI0O3"
);

export const CheckoutForm = () => {
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);
	const [planNumberDate, setPlanNumberDate] = useState(null); // Set initial state to null
	const [clientSecret, setClientSecret] = useState(null);
	const [key, setKey] = useState(0); // This will help force remounting

	// Fetch clientSecret only when button is clicked
	const fetchClientSecret = useCallback(() => {
		if (planNumberDate !== null) {
			axios
				.post(
					`${API_BASE_URL}/stripe/checkout`,
					{ plan: planNumberDate },
					{ headers }
				)
				.then((response) => {
					setClientSecret(response.data.clientSecret);
					setKey((prevKey) => prevKey + 1); // Increment key to remount the provider
				})
				.catch((error) => {
					console.error("Error fetching clientSecret:", error);
				});
		}
	}, [planNumberDate, headers]);

	return (
		<div id="checkout">
			<Box p={{ base: 6, md: 10 }}>
				<Flex mb={{ base: 4, md: 6 }}>
					<Text pt={2}>ต่ออายุจำนวน</Text>
					<Spacer />
					<Stack direction="row" spacing={2}>
						<Select
							w={"full"}
							_hover={"none"}
							variant="filled"
							colorScheme="teal"
							cursor={"pointer"}
							borderRadius={"full"}
							value={planNumberDate ?? ""} // Ensure empty string for Select
							onChange={(e) =>
								setPlanNumberDate(
									e.target.value ? Number(e.target.value) : null
								)
							}
						>
							<option value="">-เลือก-</option> {/* New option added */}
							<option value={1}>+1 ปี 730 บาท</option>
							<option value={2}>+2 ปี 1,314 บาท -10%</option>
							<option value={3}>+3 ปี 1,752 บาท -20%</option>
						</Select>

						<Button
							borderRadius={"full"}
							border={"1.5px solid"}
							borderColor={"cyan.700"}
							color={"cyan.800"}
							bgColor={"#d0e7ec"}
							fontWeight={"400"}
							onClick={fetchClientSecret} // Fetch clientSecret when button clicked
							isDisabled={!planNumberDate} // Disable if no plan is selected
						>
							ยืนยัน
						</Button>
					</Stack>
				</Flex>

				{clientSecret && (
					<EmbeddedCheckoutProvider
						key={key}
						stripe={stripePromise}
						options={{ clientSecret }}
					>
						<EmbeddedCheckout />
					</EmbeddedCheckoutProvider>
				)}
			</Box>
		</div>
	);
};

export const Return = () => {
	const navigate = useNavigate();
	const [status, setStatus] = useState(null);
	const [customerEmail, setCustomerEmail] = useState("");

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const sessionId = urlParams.get("session_id");

		fetch(`${API_BASE_URL}/stripe/session_status?session_id=${sessionId}`)
			.then((res) => res.json())
			.then((data) => {
				setStatus(data.status);
				setCustomerEmail(data.customer_email);
			});
	}, []);

	if (status === "open") {
		return (
			<Flex
				justifyContent={{ base: "auto", md: "center" }}
				alignItems={{ base: "auto", md: "center" }}
			>
				<Container maxW={"3xl"} px={"none"}>
					<Card boxShadow={"none"} borderRadius={"1em"}>
						<Box
							textAlign="center"
							py={10}
							px={6}
							display="flex"
							flexDirection="column"
							alignItems="center"
						>
							<Icon as={WarningIcon} boxSize={"50px"} color={"red.500"} />
							<Text fontSize="2xl" mt={3} mb={6}>
								การชำระเงินล้มเหลว
							</Text>
							<Text mb={6}>
								มีปัญหาในการประมวลผลการชำระเงินของคุณ
								โปรดลองอีกครั้งหรือติดต่อฝ่ายสนับสนุน
							</Text>
							<Button
								mt={6}
								colorScheme="red"
								onClick={() => navigate("/checkout")}
							>
								ลองอีกครั้ง
							</Button>
						</Box>
					</Card>
				</Container>
			</Flex>
		);
	}

	if (status === "complete") {
		return (
			<section id="success">
				<Flex
					justifyContent={{ base: "auto", md: "center" }}
					alignItems={{ base: "auto", md: "center" }}
				>
					<Container maxW={"3xl"} px={"none"}>
						<Card boxShadow={"none"} borderRadius={"1em"}>
							<Box
								textAlign="center"
								py={10}
								px={6}
								display="flex"
								flexDirection="column"
								alignItems="center"
							>
								<Icon
									as={CheckCircleIcon}
									boxSize={"50px"}
									color={"green.500"}
								/>
								<Text fontSize="2xl" mt={3} mb={6}>
									ชำระเงินสำเร็จ!
								</Text>
								<Text>
									อีเมลยืนยันได้ถูกส่งไปยัง <b>{customerEmail}</b> แล้ว <br />
									ขอบคุณสำหรับความไว้วางใจของคุณ!
								</Text>
							</Box>
						</Card>
					</Container>
				</Flex>
			</section>
		);
	}

	return null;
};
