import React from "react";
import {
	Grid,
	GridItem,
	Flex,
	chakra,
	Icon,
	SimpleGrid,
	Box,
	Stack,
	Image,
	Text,
	useBreakpointValue,
	VisuallyHidden,
} from "@chakra-ui/react";
import { FaSquareFacebook, FaLine } from "react-icons/fa6";
import { FaHotel } from "react-icons/fa";
import { IoRestaurant, IoStorefrontSharp } from "react-icons/io5";
import { MdHotel, MdCoffee } from "react-icons/md";
import { GiBeerStein } from "react-icons/gi";

import Img1 from "../../images/home_page/business/1.jpg";
import Img2 from "../../images/home_page/business/2.jpg";
import Img3 from "../../images/home_page/business/3.jpg";
import Img6 from "../../images/home_page/business/6.jpg";
import Img7 from "../../images/home_page/business/7.jpg";
import Img8 from "../../images/home_page/business/8.jpg";

import MainImg1 from "../../images/pmanual/3.jpg";
import MainImg2 from "../../images/home_page/carousels/slide_2.jpg";
import MainImg3 from "../../images/pmanual/16.jpg";
import MainImg4 from "../../images/acc/main3.jpg";
import MainImg5 from "../../images/pmanual/15.jpg";
import MainImg6 from "../../images/accommodation/acc_slide_3.jpg";

const businesses = [
	{
		src: Img1,
		icon: IoRestaurant,
		name: "ร้านอาหาร",
		link: "/restaurant",
	},
	{
		src: Img2,
		icon: GiBeerStein,
		name: "ลานเบียร์",
		link: "/restaurant",
	},
	{
		src: Img3,
		icon: MdCoffee,
		name: "ร้านกาแฟ",
		link: "/restaurant",
	},
	{
		src: Img6,
		icon: MdHotel,
		name: "รีสอร์ท",
		link: "/accommodation",
	},
	{
		src: Img7,
		icon: FaHotel,
		name: "โรงแรม",
		link: "/restaurant",
	},
	{
		src: Img8,
		icon: IoStorefrontSharp,
		name: "ร้านค้าในหอพัก",
		link: "/accommodation",
	},
];

function Intro() {
	const heightImg = useBreakpointValue({
		base: "150px",
		md: "200px",
		lg: "250px",
	});
	const heightImgRow2 = useBreakpointValue({
		base: "316px",
		md: "416px",
		lg: "516px",
	});

	return (
		<>
			<Flex
				w="full"
				bg="#edf3f8"
				_dark={{
					bg: "#3e3e3e",
				}}
				alignItems="center"
				justifyContent="center"
			>
				<Flex
					w="full"
					as="footer"
					flexDir={{
						base: "column",
						sm: "row",
					}}
					align="center"
					justify="space-between"
					px={{ base: 2, md: 6 }}
					py="4"
					bg="white"
					_dark={{
						bg: "gray.800",
					}}
				>
					<Text
						fontSize={{ base: "md", md: "lg", lg: "xl" }}
						fontWeight="bold"
						color="gray.600"
						_dark={{
							color: "white",
							_hover: {
								color: "gray.300",
							},
						}}
						bgClip="text"
						bgGradient="linear(to-r, cyan.700,orange.300)"
					>
						ระบบขายหน้าร้าน POS ออนไลน์และ QR Menu
					</Text>

					<Stack direction={"row"} spacing={2}>
						<SocialButton
							label={"Line"}
							href={"https://lin.ee/R9RbXAm"}
							color="green.500"
						>
							<FaLine fontSize="27px" />
						</SocialButton>
						<SocialButton
							label={"Facebook"}
							href={"https://www.facebook.com/100091347363576"}
							color="blue.500"
						>
							<FaSquareFacebook fontSize="30px" />
						</SocialButton>
					</Stack>
				</Flex>
			</Flex>
			<Grid
				mt={{ base: 2, md: 2, lg: 3, xl: 3 }}
				templateRows={{
					base: "repeat(3, 1fr)",
					md: "repeat(3, 1fr)",
					lg: "repeat(3, 1fr)",
				}}
				templateColumns={{
					base: "repeat(2, 1fr)",
					md: "repeat(4, 1fr)",
					lg: "repeat(5, 1fr)",
				}}
				gap={4}
			>
				<GridItem
					rowSpan={{ base: 1, md: 2, lg: 1 }}
					colSpan={{ base: 1, md: 2, lg: 1 }}
					bg="white"
				>
					<Image
						w="full"
						rounded="md"
						h={{ base: heightImgRow2, md: heightImgRow2, lg: heightImg }}
						objectFit="cover"
						objectPosition="center"
						src={MainImg1}
						alt="avatar"
					/>
				</GridItem>
				<GridItem
					rowSpan={{ base: 1, md: 2, lg: 2 }}
					colSpan={{ base: 1, md: 2 }}
					bg="white"
					pos={"relative"}
				>
					<Image
						w="full"
						rounded="md"
						h={{ base: heightImgRow2, md: heightImgRow2 }}
						objectFit="cover"
						objectPosition="center"
						src={MainImg2}
						alt="avatar"
					/>
					<Box
						pos={"absolute"}
						bottom={0}
						pl={4}
						w={"full"}
						fontWeight={"bold"}
						fontSize={"xl"}
						color={"white"}
						bgColor={"#0987a063"}
					>
						เปลี่ยนจากเมนูกระดาษมาใช้งาน QR Menu
					</Box>
				</GridItem>
				<GridItem
					rowSpan={{ base: 1, md: 2, lg: 2 }}
					colSpan={{ base: 1, md: 2, lg: 2 }}
					bg="white"
					pos={"relative"}
				>
					<Image
						w="full"
						rounded="md"
						h={heightImgRow2}
						objectFit="cover"
						objectPosition="center"
						src={MainImg5}
						alt="avatar"
					/>
					<Box
						pos={"absolute"}
						bottom={{ lg: 0 }}
						top={{ base: 0, lg: "auto" }}
						pr={{ base: 0, lg: 4 }}
						pl={{ base: 4, lg: 0 }}
						w={"full"}
						fontWeight={"bold"}
						fontSize={"xl"}
						color={"white"}
						bgColor={"#0987a063"}
						textAlign={{ base: "left", lg: "right" }}
					>
						แนะนำเมนูร้านผ่านช่องทางโซเชียล
					</Box>
				</GridItem>
				<GridItem
					rowSpan={{ base: 1, md: 2, lg: 2 }}
					colSpan={{ base: 1, md: 1, lg: 1 }}
					bg="white"
				>
					<Image
						w="full"
						rounded="md"
						h={heightImgRow2}
						objectFit="cover"
						objectPosition="center"
						src={MainImg3}
						alt="avatar"
					/>
				</GridItem>
				<GridItem
					rowSpan={{ base: 1, md: 1, lg: 1 }}
					colSpan={{ base: 1, md: 1, lg: 3 }}
					bg="white"
					pos={"relative"}
				>
					<Image
						w="full"
						rounded="md"
						h={{ base: heightImgRow2, md: heightImg, lg: heightImg }}
						objectFit="cover"
						objectPosition="center"
						src={MainImg6}
						alt="avatar"
					/>
					<Box
						pos={"absolute"}
						bottom={0}
						pr={4}
						w={"full"}
						fontWeight={"bold"}
						fontSize={"xl"}
						color={"white"}
						bgColor={"#0987a063"}
						textAlign={"right"}
					>
						เป็นช่องทางนำเสนอสินค้าและบริการ
					</Box>
				</GridItem>
				<GridItem
					rowSpan={{ base: 1, md: 1, lg: 1 }}
					colSpan={{ base: 1, md: 1, lg: 1 }}
					bg="white"
				>
					<Image
						w="full"
						rounded="md"
						h={{ base: heightImgRow2, md: heightImg }}
						objectFit="cover"
						objectPosition="center"
						src={MainImg4}
						alt="avatar"
					/>
				</GridItem>
			</Grid>
			<SimpleGrid
				columns={{ base: 2, md: 3, lg: 6, xl: 6 }}
				spacing={{ base: 2, md: 2, lg: 3, xl: 3 }}
				mt={{ base: 2, md: 2, lg: 3, xl: 3 }}
			>
				{businesses.map((business, index) => (
					<BusinessCard key={index} business={business} />
				))}
			</SimpleGrid>
		</>
	);
}

const BusinessCard = ({ business }) => (
	<Box
		w={"full"}
		mx="auto"
		bg="white"
		_dark={{ bg: "gray.800" }}
		shadow="lg"
		rounded="md"
		overflow="hidden"
		_hover={{ boxShadow: "0 0 20px #00000056" }}
	>
		<Stack direction={{ base: "row", md: "column" }} spacing={0}>
			<Image
				w={{ base: 20, md: "full" }}
				h={{ base: 20, md: 40, xl: 44 }}
				objectFit="cover"
				objectPosition="center"
				src={business.src}
				alt="avatar"
			/>
			<Flex
				alignItems="center"
				justifyContent={{ base: "left", md: "center" }}
				px={{ base: 3, md: 0 }}
				py={2}
				bg="gray.900"
				w={"full"}
			>
				<Icon as={business.icon} color="white" />
				<chakra.h2 mx={3} color="white" fontWeight="bold" fontSize="sm">
					{business.name}
				</chakra.h2>
			</Flex>
		</Stack>
	</Box>
);

const SocialButton = ({ children, label, href, color }) => {
	return (
		<chakra.button
			color={color}
			rounded={"full"}
			w={8}
			h={8}
			cursor={"pointer"}
			as={"a"}
			href={href}
			display={"inline-flex"}
			alignItems={"center"}
			justifyContent={"center"}
			transition={"background 0.3s ease"}
			target="_blank"
		>
			<VisuallyHidden>{label}</VisuallyHidden>
			{children}
		</chakra.button>
	);
};

export default Intro;
