import React, { useMemo, useEffect, useState } from "react";
import axios from "axios";
import {
	Box,
	Flex,
	Card,
	Container,
	Spacer,
	Text,
	Badge,
	Link,
} from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/AuthProvider";
import { API_BASE_URL } from "../../config/apiConfig";

function Renew() {
	const navigate = useNavigate();
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);

	const [lastDate, setLastDate] = useState("");
	const [updateDateShow, setUpdateDateShow] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/last_date`,
					{},
					{ headers }
				);

				// console.log(response.data);
				const res = response.data.results[0];
				setLastDate(res.last_day);
			} catch (error) {
				console.error("Error last_date:", error);
			}
		};

		if (headers) {
			fetchData();
		}
		if (updateDateShow) {
			fetchData();
			setUpdateDateShow(false);
		}
	}, [headers, updateDateShow]);

	const convertToThaiDate = (dateTimeString) => {
		// console.log(dateTimeString);
		const dateTime = new Date(dateTimeString);
		const options = {
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
		};
		const thaiDateTime = dateTime.toLocaleString("th-TH", options);
		return thaiDateTime;
	};

	const isExpired = (dateTimeString) => {
		const currentDate = new Date();
		const lastDateTime = new Date(dateTimeString);
		return lastDateTime < currentDate; // Returns true if lastDate is before currentDate
	};

	return (
		<>
			<Flex
				justifyContent={{ base: "auto", md: "center" }}
				alignItems={{ base: "auto", md: "center" }}
			>
				<Container maxW={"3xl"} px={"none"}>
					<Card boxShadow={"none"} borderRadius={"1em"}>
						<Box
							p={{ base: 6, md: 10 }}
							textAlign={"center"}
							boxShadow={"md"}
							borderBottomRadius={"2xl"}
						>
							<Flex py={2}>
								<Box>
									<Text fontSize={"lg"} fontWeight={"500"}>
										อายุการใช้งานถึง
									</Text>
									<Box>
										{lastDate !== "" && isExpired(lastDate) && (
											<Badge colorScheme="red" size={"sm"}>
												หมดอายุ
											</Badge>
										)}
									</Box>
								</Box>
								<Spacer />
								<Box textAlign={"right"}>
									<Text>
										{lastDate === "" ? (
											<BeatLoader size={8} color="gray" />
										) : (
											convertToThaiDate(lastDate)
										)}
									</Text>
									<Link
										color={"blue.400"}
										onClick={() => navigate("/checkout")}
									>
										ต่ออายุ
									</Link>
								</Box>
							</Flex>
						</Box>
						<Outlet />
					</Card>
				</Container>
			</Flex>
		</>
	);
}

export default Renew;
