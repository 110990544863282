import React, { useState, useEffect } from "react";
import axios from "axios";
import { Flex, Box, Image, ScaleFade, Text } from "@chakra-ui/react";
import { API_BASE_URL } from "../config/apiConfig";
import socket from "../config/socket";
import HistoryOrder from "../components/component/employee/HistoryOrder";

function MobileApp() {
	const [shopData, setShopData] = useState([]);
	useEffect(() => {
		const checkEmployeeLogin = async () => {
			const response = await axios.post(`${API_BASE_URL}/mobile/mobile_login`, {
				trimmedRestaurantKey: "SHOP02DEMO",
				trimmedEmployeeKey: "vs",
				deviceInfo: {
					expoPushToken: "ExponentPushToken[8ZIgNnEW7blhMJqCQLtiLV]",
					brand: "xiaomi",
					model: "redmi 4x",
				},
			});

			if (response.data.message === "success") {
				console.log(response.data);
				setShopData(response.data);
			}
		};
		checkEmployeeLogin();
	}, []);

	return (
		<>
			<Flex
				as="header"
				width="100%"
				height="60px"
				alignItems="center"
				padding="0 20px"
				bg="cyan.800"
				boxShadow="sm"
				px={{ base: 4, sm: 6, md: 10, lg: 14, xl: 16 }}
				position={"fixed"}
				top={0}
				zIndex={2}
			>
				<Flex gap={3}>
					<Image
						src={
							shopData.shopLogo !== ""
								? `${API_BASE_URL}/image/logo/${shopData.shopLogo}`
								: `${API_BASE_URL}/image/logo/default.png`
						}
						alt="Logo"
						height="40px"
						filter="drop-shadow(0 0 5px white)"
					/>
					<Box color={"white"} lineHeight="1.2" pt={1}>
						<Text fontWeight={"bold"} fontSize={"lg"}>
							{shopData.shopName}
						</Text>
						<Text fontWeight={"300"} fontSize={"10px"}>
							sevscan
						</Text>
					</Box>
				</Flex>
			</Flex>

			<ScaleFade initialScale={0.6} in={true}>
				<Box
					px={{ base: 4, md: 10, lg: 14, xl: 16 }}
					py={{ base: 5, md: 6, lg: 8, xl: 10 }}
					mt={"60px"}
					minH="calc(100vh - 60px)"
				>
					<EmployeeDisplay shopData={shopData} />
				</Box>
			</ScaleFade>
		</>
	);
}

function EmployeeDisplay({ shopData }) {
	// console.log(shopValueId);

	const shopValueId = shopData.shopId;

	const [flashLights, setFlashLights] = useState(
		window.navigator.onLine ? true : false
	);
	const [checkLastDate, setCheckLastDate] = useState();

	useEffect(() => {
		socket.emit("join_room", shopData.shopId);

		socket.on("connect", () => {
			setFlashLights(true);
			socket.emit("join_room", shopData.shopId);
		});
		socket.on("disconnect", () => {
			socket.emit("leave_room", shopData.shopId);
			setFlashLights(false);
		});
	}, [shopData]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/employee/check_last_day`,
					{ shopValueId },
					{}
				);
				// console.log("last_day", response.data);
				setCheckLastDate(response.data.results[0].last_day);
			} catch (error) {
				setFlashLights(false);
			}
		};

		if (shopValueId) {
			fetchData();
		}
	}, [shopValueId]);

	if (!flashLights) {
		return (
			<Box
				px={2}
				fontSize={"md"}
				color={"red"}
				borderRadius={"full"}
				pos={"absolute"}
				top={"50%"}
				left={"50%"}
				transform={"translateX(-50%)"}
				whiteSpace={"nowrap"}
			>
				ไม่สามารถเชื่อมต่อระบบได้
			</Box>
		);
	}

	if (checkLastDate) {
		return (
			<Box
				px={2}
				fontSize={"md"}
				color={"red"}
				borderRadius={"full"}
				pos={"absolute"}
				top={"50%"}
				left={"50%"}
				transform={"translateX(-50%)"}
				whiteSpace={"nowrap"}
			>
				หมดอายุการใช้งานแล้ว
			</Box>
		);
	}

	return (
		<>
			<HistoryOrder shopValueId={shopValueId} />
		</>
	);
}

export default MobileApp;
