import React from "react";
import {
	SimpleGrid,
	Box,
	Flex,
	Icon,
	Heading,
	GridItem,
	Text,
	Stack,
	Image,
	chakra,
} from "@chakra-ui/react";
import QRCode from "react-qr-code";
import Carousels from "./component/Carousels";
import ExampleButton from "./component/ExampleButton";
import CardRegister from "./component/CardRegister";
import res_system_1 from "../images/pmanual/res_system_1.png";
import res_system_2 from "../images/pmanual/res_system_2.png";
import res_system_3 from "../images/pmanual/res_system_3.png";

import res_slide_1 from "../images/restaurant/res_slide_1.jpg";
import res_slide_2 from "../images/restaurant/res_slide_2.jpg";
import res_slide_3 from "../images/restaurant/res_slide_3.jpg";
import res_slide_4 from "../images/restaurant/res_slide_4.jpg";
import res_slide_5 from "../images/restaurant/res_slide_5.jpg";
import res_slide_6 from "../images/restaurant/res_slide_6.jpg";
import res_slide_7 from "../images/restaurant/res_slide_7.jpg";
import res_slide_8 from "../images/restaurant/res_slide_8.jpg";
import res_slide_9 from "../images/restaurant/res_slide_9.jpg";
import res_slide_10 from "../images/restaurant/res_slide_10.jpg";
import res_slide_11 from "../images/restaurant/res_slide_11.jpg";
import res_slide_12 from "../images/restaurant/res_slide_12.jpg";

const initialSlides = [
	{
		img: res_slide_1,
		// label: "ร้านอาหาร",
	},
	{
		img: res_slide_2,
		// label: "2 สวนอาหาร",
	},
	{
		img: res_slide_3,
		// label: "3 สวนอาหาร",
	},
	{
		img: res_slide_4,
		// label: "4 สวนอาหาร",
	},
	{
		img: res_slide_5,
		// label: "5 สวนอาหาร",
	},
	{
		img: res_slide_6,
		// label: "6 สวนอาหาร",
	},
	{
		img: res_slide_7,
		// label: "7 สวนอาหาร",
	},
	{
		img: res_slide_8,
		// label: "8 สวนอาหาร",
	},
	{
		img: res_slide_9,
		// label: "ลานเบียร์",
	},
	{
		img: res_slide_10,
		// label: "10 สวนอาหาร",
	},
	{
		img: res_slide_11,
		// label: "11 สวนอาหาร",
	},
	{
		img: res_slide_12,
		// label: "12 สวนอาหาร",
	},
];

function Restaurant() {
	const Feature = (props) => {
		return (
			<Flex pb={2}>
				<Flex shrink={0}>
					<Icon
						boxSize={5}
						mt={1}
						mr={2}
						color="brand.500"
						_dark={{
							color: "brand.300",
						}}
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
							clipRule="evenodd"
						></path>
					</Icon>
				</Flex>
				<Box ml={{ base: 0, md: 4 }}>
					<Heading
						fontSize="lg"
						fontWeight="500"
						lineHeight="6"
						_light={{
							color: "gray.800",
						}}
						mb={2}
					>
						{props.title}
					</Heading>
					<Text
						color="gray.500"
						_dark={{
							color: "gray.400",
						}}
					>
						{props.children}
					</Text>
				</Box>
			</Flex>
		);
	};
	const Feature2 = (props) => {
		return (
			<Box w={"300px"}>
				<Image src={props.img} mb={2} />
				<Heading
					as={"h3"}
					mb={{ base: 1, md: 3 }}
					fontSize="lg"
					lineHeight="shorter"
					fontWeight="bold"
					_light={{
						color: "cyan.700",
					}}
					textAlign={"center"}
				>
					{props.title}
				</Heading>
			</Box>
		);
	};
	const ExampleCard = (props) => {
		return (
			<Flex
				rounded="lg"
				bg={"gray.100"}
				_dark={{
					bg: "gray.700",
				}}
				direction="column"
				alignItems="center"
				justifyContent="center"
			>
				<Box
					bgImage={props.img}
					bgSize="cover"
					bgPosition="center"
					bgRepeat="no-repeat"
					height="70px"
					width="100%"
					borderTopRadius="lg"
					p={8}
					display="flex"
					alignItems="left"
				>
					<Box
						boxSize={{ base: "55px", md: "60px", lg: "60px", xl: "65px" }}
						shadow="lg"
						borderColor="gray.800"
						bgColor={"white"}
						p={1}
					>
						<QRCode
							size={256}
							style={{
								height: "auto",
								maxWidth: "100%",
								width: "100%",
							}}
							value={props.link}
							viewBox={`0 0 256 256`}
						/>
					</Box>
				</Box>
				<Box
					gridColumn="span 8"
					px={5}
					pt={{ base: 1, lg: 2 }}
					pb={3}
					width="full"
					borderRadius="lg"
					textAlign="right"
				>
					<Text
						fontSize={{ base: "md", md: "md", lg: "lg" }}
						fontWeight="400"
						color="gray.800"
						_dark={{
							color: "white",
						}}
					>
						{props.name}
					</Text>
				</Box>
			</Flex>
		);
	};

	return (
		<>
			<Box px={{ base: 0, md: 5 }}>
				<Carousels initialSlides={initialSlides} />
			</Box>

			<Box px={{ base: 0, md: 5 }} pt={{ base: 4, md: 10 }}>
				<Flex
					px={{ base: 2, md: 10 }}
					py={{ base: 5, md: 10, lg: 12, xl: 16 }}
					w="full"
					justifyContent="center"
					alignItems="center"
				>
					<Box px={{ base: 2, md: 8 }} mx="auto">
						<SimpleGrid
							w={"full"}
							alignItems="center"
							columns={{
								base: 1,
								lg: 3,
							}}
							spacingY={{
								base: 10,
								lg: 32,
							}}
							spacingX={{
								base: 0,
								lg: 24,
							}}
						>
							<Box alignSelf={{ base: "center", md: "start" }} w={"full"}>
								<Text
									mb={4}
									fontSize={{ base: "md", md: "lg" }}
									textAlign={{ base: "center", sm: "left" }}
									color="gray.600"
								>
									เรามุ่งหวังให้ร้านของคุณมีรายได้เพิ่มขึ้นด้วยระบบของเรา
									ที่ช่วยให้ลูกค้าสามารถสั่งซื้อสินค้าได้ตลอดเวลา
									ลดภาระงานของพนักงานรับออเดอร์ บันทึกรายการทั้งหมดลงระบบ
									สรุปรายได้รวมของแต่ละวัน
									โดยที่ทางร้านไม่จะเป็นต้องซื้อเครื่องมือราคาแพงหรือระบบที่มีค่าใช้จ่ายที่สูง
								</Text>
								<Text
									mb={6}
									fontSize={{ base: "md", md: "lg" }}
									textAlign={{ base: "center", sm: "left" }}
									color="gray.600"
								>
									ใช้งานบนเว็บง่ายไม่ต้องโหลดแอพเพิ่มเติมใดๆ
								</Text>
								<Flex gap={3} justify={{ base: "center", md: "left" }} mb="2">
									<ExampleButton />
								</Flex>
							</Box>
							<GridItem colSpan={2}>
								<Stack
									spacing={{
										base: 10,
										md: 0,
									}}
									display={{
										md: "grid",
									}}
									gridTemplateColumns={{
										md: "repeat(2,1fr)",
									}}
									gridColumnGap={{
										md: 8,
									}}
									gridRowGap={{
										md: 10,
									}}
								>
									<Feature title="ลดพนักงานรับออเดอร์">
										ระบบ QR Menu
										จะช่วยให้พนักงานไม่ต้องมายืนรอรับออเดอร์และเสียเวลากับการรอคอย
										หรือหากร้านไหนที่มีพนักงานในส่วนนี้จำนวนมากก็สามารถลดลงได้
									</Feature>
									<Feature title="ลูกค้าสั่งออเดอร์ได้ตลอด">
										ระบบ QR Menu
										ที่ลูกค้าสามารถสแกนดูสินค้าได้และหากต้องการก็สามารถสั่งซื้อได้ตลอดเวลา
										โดยไม่ต้องเรียกพนักงานมารับออเดอร์
									</Feature>
									<Feature title="บันทึกออเดอร์ลงระบบ">
										หากมีคำสั่งซื้อมาที่ร้าน ระบบแจ้งเตือนรายการไปที่ LINE
										และจะบันทึกรายการไว้สำหรับตรวจสอบรายการทั้งหมดที่ลูกค้าสั่งเวลาจะคิดเงินได้
									</Feature>
									<Feature title="เรียกพนักงานจากในระบบได้">
										เวลาที่ลูกค้ามีปัญหา
										หรือต้องการเรียกพนักงานคิดเงินก็สามารถกดเรียกพนักงานจากในระบบได้โดยไม่ต้องตะโกนหรือยกมือเรียก
									</Feature>
									<Feature title="อัพเดทยอดขายแบบทันที">
										สำหรับเจ้าของร้านที่ต้องการทราบยอดขาย ณ เวลานั้นๆ
										ก็สามารถเข้าดูได้ ระบบจะมีหน้าแดชบอร์ดแสดงยอดขาย
										จำนวนคำสั่งซื้อ รายการสั่งซื้อล่าสุด และสินค้าขายดีให้ดูได้
									</Feature>
									<Feature title="ช่วยเพิ่มยอดขายต่อบิล">
										หากระบบช่วยให้ลูกค้าของทางร้านสามารถกดดูสินค้าตอนไหนก็ได้
										หรือดูได้อย่างอิสระไม่ต้องกังวลกับการยืนคอยของพนักงาน
										การสั่งซื้อก็จะสามารถเกินขึ้นได้ตลอดเวลา
									</Feature>
								</Stack>
							</GridItem>
						</SimpleGrid>
					</Box>
				</Flex>
			</Box>

			<Box px={{ base: 0, md: 5 }} py={{ base: 4, md: 8, xl: 10 }}>
				<Flex w="auto" justifyContent="center" alignItems="center" gap={10}>
					<Feature2 title="แอดมินจัดการร้าน" img={res_system_1}></Feature2>
					<Feature2 title="ใช้งาน QR Menu" img={res_system_2}></Feature2>
					<Feature2 title="แจ้งรายการผ่าน LINE" img={res_system_3}></Feature2>
					<Feature2 title="จดออเดอร์บนมือถือ" img={res_system_3}></Feature2>
				</Flex>
			</Box>

			<Box px={{ base: 0, md: 5 }} pt={{ base: 4, md: 10 }} display={"none"}>
				<Flex
					px={{ base: 4, md: 10, lg: 20 }}
					pb={{ base: 5, md: 20 }}
					w="auto"
					justifyContent="center"
					alignItems="center"
				>
					<Box w={"full"} px={{ md: 0, xl: 10 }}>
						<Box textAlign={{ lg: "center" }} pb={4}>
							<chakra.p
								mt={2}
								fontWeight={"600"}
								fontSize={{ base: "2xl", sm: "4xl" }}
								lineHeight="8"
								letterSpacing="tight"
								_light={{
									color: "gray.900",
								}}
							>
								ตัวอย่างการใช้งาน
							</chakra.p>
						</Box>
						<SimpleGrid
							columns={{ base: 2, md: 3, lg: 3, xl: 4 }}
							mt={6}
							spacing={{ base: 5, xl: 10 }}
						>
							<ExampleCard
								name={"ร้านอาหาร"}
								img={
									"https://www.chillpainai.com/src/wewakeup/scoop/images/cc9c04ac5c96c468fd8cb7271fc1dd24d88067ad.jpg"
								}
								link={"https://www.sevscan.com/menu/DEMOSHOP01"}
							/>
							<ExampleCard
								name={"ร้านอาหาร 2"}
								img={
									"https://www.chillpainai.com/src/wewakeup/scoop/images/cc9c04ac5c96c468fd8cb7271fc1dd24d88067ad.jpg"
								}
								link={"https://www.sevscan.com/menu/DEMOSHOP02"}
							/>
							<ExampleCard
								name={"ร้านอาหาร 3"}
								img={
									"https://www.chillpainai.com/src/wewakeup/scoop/images/cc9c04ac5c96c468fd8cb7271fc1dd24d88067ad.jpg"
								}
								link={"https://www.sevscan.com/menu/DEMOSHOP03"}
							/>
							<ExampleCard
								name={"ร้านอาหาร 4"}
								img={
									"https://www.chillpainai.com/src/wewakeup/scoop/images/cc9c04ac5c96c468fd8cb7271fc1dd24d88067ad.jpg"
								}
								link={"https://www.sevscan.com/menu/DEMOSHOP04"}
							/>
							<ExampleCard
								name={"ร้านอาหาร 5"}
								img={
									"https://www.chillpainai.com/src/wewakeup/scoop/images/cc9c04ac5c96c468fd8cb7271fc1dd24d88067ad.jpg"
								}
								link={"https://www.sevscan.com/menu/DEMOSHOP05"}
							/>
							<ExampleCard
								name={"ร้านอาหาร 6"}
								img={
									"https://www.chillpainai.com/src/wewakeup/scoop/images/cc9c04ac5c96c468fd8cb7271fc1dd24d88067ad.jpg"
								}
								link={"https://www.sevscan.com/menu/DEMOSHOP06"}
							/>
							<ExampleCard
								name={"ร้านอาหาร 7"}
								img={
									"https://www.chillpainai.com/src/wewakeup/scoop/images/cc9c04ac5c96c468fd8cb7271fc1dd24d88067ad.jpg"
								}
								link={"https://www.sevscan.com/menu/DEMOSHOP07"}
							/>
							<ExampleCard
								name={"ร้านอาหาร 8"}
								img={
									"https://www.chillpainai.com/src/wewakeup/scoop/images/cc9c04ac5c96c468fd8cb7271fc1dd24d88067ad.jpg"
								}
								link={"https://www.sevscan.com/menu/DEMOSHOP08"}
							/>
						</SimpleGrid>
					</Box>
				</Flex>
			</Box>

			<Box pt={{ base: 4, md: 10 }}>
				<CardRegister />
			</Box>
		</>
	);
}

export default Restaurant;
