import React from "react";
import {
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	Image,
	Flex,
	Spacer,
	useColorModeValue,
} from "@chakra-ui/react";
import { PiMonitorBold } from "react-icons/pi";
import bg_monitor from "../../images/bg_monitor.jpg";
import bg_monitor2 from "../../images/bg_monitor2.jpg";
import MonitorDisplay from "../component/MonitorDisplay";
import logo from "../../images/logo250.png";

function ManageOrder() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<Box>
				<Button
					leftIcon={<PiMonitorBold />}
					colorScheme="orange"
					variant="outline"
					fontWeight={"500"}
					onClick={onOpen}
					display={"none"}
				>
					รับออเดอร์หน้าร้าน
				</Button>
			</Box>

			<Modal isOpen={isOpen} onClose={onClose} size={"full"}>
				<ModalOverlay />
				<ModalContent>
					<ModalBody pt={{ base: 5, md: 10 }} px={0}>
						<Flex
							pos={"fixed"}
							top={0}
							bgColor={useColorModeValue("cyan.800", "cyan.900")}
							w={"full"}
							p={4}
							boxShadow="0 0 2px #d5d5d5"
							zIndex="sticky"
						>
							<Image src={logo} h={8} />
							<Spacer />
							<ModalCloseButton mt={2} mr={1} color={"white"} />
						</Flex>
						<Box
							px={{ base: 5, md: 10, lg: 16, xl: 20 }}
							pt={{ base: 16, md: 20, lg: 20 }}
							pb={{ base: 8, md: 10, lg: 10 }}
							bgImg={useColorModeValue(bg_monitor, bg_monitor2)}
							minH="calc(100vh - 48px)"
						>
							<MonitorDisplay />
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default ManageOrder;
