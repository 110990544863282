import React, { useState, useMemo, useEffect, useContext } from "react";
import axios from "axios";
import {
	Flex,
	Box,
	Card,
	Container,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	Input,
	ModalFooter,
	Button,
	useDisclosure,
	SimpleGrid,
	FormLabel,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
	Center,
	useColorModeValue,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { API_BASE_URL } from "../../config/apiConfig";
import { useAuth } from "../../hooks/AuthProvider";
import ShopContext from "../../hooks/ShopContext";
import ImageDownloadAPK from "../../images/image_download_apk.jpg";

function Employee() {
	const { shopValueId } = useContext(ShopContext);
	const toast = useToast();
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);

	const [employee, setEmployee] = useState({});
	const [oldPassword, setOldPassword] = useState();
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/employee`,
					{ shopValueId },
					{ headers }
				);
				// console.log("em", response.data.results);
				setEmployee(response.data.results);
				setOldPassword(response.data.results[0].password);
			} catch (error) {
				console.error("Error get employee:", error);
			}
		};

		if (shopValueId && headers) {
			fetchData();
		}
	}, [toast, headers, shopValueId]);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [password, setPassword] = useState();
	const handleEdit = () => {
		// console.log(data);
		setPassword(oldPassword);
		onOpen();
	};
	const handleSave = async () => {
		const trimmedPassword = password.trim();
		// console.log("trimmedPassword", trimmedPassword);

		if (!trimmedPassword) {
			toast({
				title: `กรอกรหัสผ่าน`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}

		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/employee_update_password`,
				{
					editId: employee[0].id,
					trimmedPassword,
				},
				{ headers }
			);
			if (response.data.message === "success") {
				setOldPassword(trimmedPassword);
				onClose();
				toast({
					title: `เรียบร้อย`,
					position: "top",
					isClosable: true,
					status: "success",
					variant: "top-accent",
					duration: 5000,
				});
			} else {
				console.error("Error category_add");
			}
		} catch (error) {
			console.error("Error saving category:", error);
		}
	};

	return (
		<>
			<Flex alignItems={{ base: "auto", md: "center" }}>
				<Container maxW={"3xl"} px={"none"}>
					<Card boxShadow={"none"} borderRadius={"1em"}>
						<Center>
							<Box
								pos={"relative"}
								h={{ base: "200px", sm: "230px", md: "220px", lg: "300px" }}
								w={"full"}
								bg={useColorModeValue("white", "gray.800")}
								borderTopRadius={"1em"}
								overflow={"hidden"}
								bgImage={ImageDownloadAPK}
								bgSize="cover" // or "contain", depending on desired fit
								bgPosition="center"
								bgRepeat="no-repeat"
							>
								<Button
									pos={"absolute"}
									right={4}
									bottom={4}
									px={5}
									colorScheme="whiteAlpha"
									rounded={"md"}
									_hover={{
										transform: "translateY(-2px)",
										boxShadow: "lg",
									}}
									onClick={() =>
										(window.location.href =
											"https://file.sevscan.com/app_sevscan.apk")
									}
									size={{ base: "sm", md: "md" }}
								>
									โหลดไฟล์ APK
								</Button>
								<Button
									pos={"absolute"}
									left={4}
									top={4}
									colorScheme="blackAlpha"
									size={{ base: "sm", md: "md" }}
									leftIcon={
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="1.2em"
											height="1.2em"
											viewBox="0 0 256 283"
										>
											<path
												fill="#ea4335"
												d="M119.553 134.916L1.06 259.061a32.14 32.14 0 0 0 47.062 19.071l133.327-75.934z"
											/>
											<path
												fill="#fbbc04"
												d="M239.37 113.814L181.715 80.79l-64.898 56.95l65.162 64.28l57.216-32.67a31.345 31.345 0 0 0 0-55.537z"
											/>
											<path
												fill="#4285f4"
												d="M1.06 23.487A30.6 30.6 0 0 0 0 31.61v219.327a32.3 32.3 0 0 0 1.06 8.124l122.555-120.966z"
											/>
											<path
												fill="#34a853"
												d="m120.436 141.274l61.278-60.483L48.564 4.503A32.85 32.85 0 0 0 32.051 0C17.644-.028 4.978 9.534 1.06 23.399z"
											/>
										</svg>
									}
								>
									Play Store เร็วๆ นี้
								</Button>
							</Box>
						</Center>
						<Box p={{ base: 6, md: 10 }}>
							<SimpleGrid
								columns={{ base: 1, md: 2 }}
								spacing={{ base: 3, md: 5 }}
							>
								{/* <Box fontWeight={"bold"} fontSize={"lg"} color={"cyan.700"}>
									โหลดแอพพลิเคชั่น Android
								</Box>
								<Box
									color={"gray.500"}
									pb={{ base: 3, md: 0 }}
									textAlign={"right"}
								>
									<Center py={6}>
										<Box
											w={"full"}
											bg={useColorModeValue("white", "gray.800")}
											boxShadow={"2xl"}
											rounded={"md"}
											overflow={"hidden"}
										>
											<Image
												h={"120px"}
												w={"full"}
												src={
													"https://images.unsplash.com/photo-1612865547334-09cb8cb455da?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
												}
												objectFit="cover"
												alt="#"
											/>
											<Flex justify={"center"} mt={-12}>
												<Avatar
													size={"xl"}
													src={
														"https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ"
													}
													css={{
														border: "2px solid white",
													}}
												/>
											</Flex>

											<Box p={6}>
												<Stack spacing={0} align={"center"} mb={5}>
													<Text color={"gray.500"}>Frontend Developer</Text>
												</Stack>

												<Stack direction={"row"} justify={"center"} spacing={6}>
													<Stack spacing={0} align={"center"}>
														<Text fontWeight={600}>23k</Text>
														<Text fontSize={"sm"} color={"gray.500"}>
															Followers
														</Text>
													</Stack>
													<Stack spacing={0} align={"center"}>
														<Text fontWeight={600}>23k</Text>
														<Text fontSize={"sm"} color={"gray.500"}>
															Followers
														</Text>
													</Stack>
												</Stack>

												<Button
													w={"full"}
													mt={8}
													bg={useColorModeValue("orange.300", "gray.700")}
													color={"white"}
													rounded={"md"}
													_hover={{
														transform: "translateY(-2px)",
														boxShadow: "lg",
													}}
													onClick={() =>
														(window.location.href =
															"https://file.sevscan.com/app_sevscan.apk")
													}
												>
													โหลดไฟล์ APK
												</Button>
												<Button
													display={"none"}
													leftIcon={
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="1.5em"
															height="1.5em"
															viewBox="0 0 16 16"
														>
															<path
																fill="#fff"
																d="M14 6c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1s1-.45 1-1V7c0-.55-.45-1-1-1M2 6c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1s1-.45 1-1V7c0-.55-.45-1-1-1m1.5 5.5A1.5 1.5 0 0 0 5 13v2c0 .55.45 1 1 1s1-.45 1-1v-2h2v2c0 .55.45 1 1 1s1-.45 1-1v-2a1.5 1.5 0 0 0 1.5-1.5V6h-9zM12.472 5a4.5 4.5 0 0 0-2.025-3.276l.5-1.001a.5.5 0 0 0-.895-.447L9.55 1.28l-.13-.052a4.5 4.5 0 0 0-2.84 0l-.13.052L5.948.276a.5.5 0 0 0-.895.447l.5 1.001A4.5 4.5 0 0 0 3.528 5v.5H12.5V5zM6.5 4a.5.5 0 0 1-.001-1h.002A.5.5 0 0 1 6.5 4m3 0a.5.5 0 0 1-.001-1h.003a.5.5 0 0 1-.001 1z"
															/>
														</svg>
													}
													bgColor="orange.300"
													color={"white"}
													variant="solid"
													onClick={() =>
														(window.location.href =
															"https://file.sevscan.com/app_sevscan.apk")
													}
													_hover={{
														bgColor: "green.400",
													}}
													size={"lg"}
													px={10}
												>
													โหลดไฟล์ APK
												</Button>
											</Box>
										</Box>
									</Center>
								</Box> */}
								<Box fontWeight={"bold"} fontSize={"lg"} color={"cyan.700"}>
									รหัสร้าน
								</Box>
								<Box
									color={"gray.500"}
									pb={{ base: 3, md: 0 }}
									textAlign={"right"}
								>
									{employee.length > 0 && employee[0].code}
								</Box>
								<Box fontWeight={"bold"} fontSize={"lg"} color={"cyan.700"}>
									รหัสผ่านพนักงาน
								</Box>
								<Box
									color={"gray.500"}
									pb={{ base: 3, md: 0 }}
									textAlign={"right"}
								>
									{oldPassword}
								</Box>
							</SimpleGrid>
						</Box>
						<Box
							textAlign={"right"}
							px={10}
							pb={5}
							boxShadow={"md"}
							borderBottomRadius={"2xl"}
						>
							<EditIcon
								onClick={() => handleEdit()}
								color="cyan.700"
								cursor="pointer"
							/>
						</Box>
						<Box pt={3}>
							<TableContainer>
								<Table variant="simple">
									<TableCaption>อุปกรณ์ที่ติดตั้งแอพ</TableCaption>
									<Thead>
										<Tr>
											<Th color={"cyan.700"} fontSize={"md"}>
												แบรนด์
											</Th>
											<Th color={"cyan.700"} fontSize={"md"} isNumeric>
												รุ่นโมเดล
											</Th>
										</Tr>
									</Thead>
									<Tbody>
										{employee[0]?.brand ? (
											employee.map((item, index) => (
												<Tr key={index}>
													<Td>{item.brand}</Td>
													<Td isNumeric>{item.model}</Td>
												</Tr>
											))
										) : (
											<Tr>
												<Td colSpan={2} textAlign={"center"}>
													- ยังไม่มี -
												</Td>
											</Tr>
										)}
									</Tbody>
								</Table>
							</TableContainer>
						</Box>
					</Card>
				</Container>
			</Flex>

			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody>
						<FormLabel>เปลี่ยนรหัสผ่านพนักงาน</FormLabel>
						<Input
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" onClick={onClose} mr={2}>
							ยกเลิก
						</Button>
						<Button colorScheme="telegram" onClick={() => handleSave()}>
							ยืนยัน
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Employee;
