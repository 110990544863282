import { useNavigate } from "react-router-dom";
import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	HStack,
	InputRightElement,
	Stack,
	Button,
	Heading,
	useColorModeValue,
	useToast, // Import useToast
} from "@chakra-ui/react";
import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import axios from "axios";
import { API_BASE_URL } from "../../config/apiConfig";

export default function Register() {
	const navigate = useNavigate();
	const toast = useToast(); // Initialize useToast

	const [showPassword, setShowPassword] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const handleSubmit = async () => {
		if (
			!firstName.trim() ||
			!lastName.trim() ||
			!email.trim() ||
			!password.trim() ||
			!confirmPassword.trim()
		) {
			toast({
				title: "กรุณากรอกข้อมูลให้ครบถ้วน",
				status: "warning",
				duration: 3000,
				isClosable: true,
			});
			return;
		}

		// Check email format
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(email)) {
			toast({
				title: "รูปแบบอีเมลไม่ถูกต้อง",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
			return;
		}

		if (password !== confirmPassword) {
			toast({
				title: "รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
			return;
		}

		const data = {
			firstName: firstName.trim(),
			lastName: lastName.trim(),
			email: email.trim(),
			password: password.trim(),
		};

		try {
			const response = await axios.post(`${API_BASE_URL}/team/register`, data);

			if (response.data.message === "success") {
				toast({
					title: "สมัครสมาชิกสำเร็จ!",
					status: "success",
					duration: 3000,
					isClosable: true,
				});
				navigate("/team/login");
			} else {
				toast({
					title: response.data.message,
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			}
		} catch (error) {
			console.error("Error during registration:", error);
			toast({
				title: "เกิดข้อผิดพลาดในการสมัครสมาชิก",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		}
	};

	return (
		<Flex align="center" justify="center" minH="calc(100vh - 96px)">
			<Stack w={{ base: "full", sm: "auto" }} py={12}>
				<Box
					rounded="lg"
					bg={useColorModeValue("white", "gray.700")}
					p={8}
					w={{
						base: "full",
						sm: "480px",
						md: "480px",
					}}
				>
					<Stack align="center" pb={8}>
						<Heading fontSize="4xl" textAlign="center">
							สมัครสมาชิก
						</Heading>
					</Stack>
					<Stack spacing={4}>
						<HStack>
							<Box>
								<FormControl id="firstName" isRequired>
									<FormLabel>ชื่อ</FormLabel>
									<Input
										type="text"
										value={firstName}
										onChange={(e) => setFirstName(e.target.value)}
									/>
								</FormControl>
							</Box>
							<Box>
								<FormControl id="lastName" isRequired>
									<FormLabel>สกุล</FormLabel>
									<Input
										type="text"
										value={lastName}
										onChange={(e) => setLastName(e.target.value)}
									/>
								</FormControl>
							</Box>
						</HStack>
						<FormControl id="email" isRequired>
							<FormLabel>อีเมล</FormLabel>
							<Input
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</FormControl>
						<FormControl id="password" isRequired>
							<FormLabel>รหัสผ่าน</FormLabel>
							<InputGroup>
								<Input
									type={showPassword ? "text" : "password"}
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<InputRightElement h="full">
									<Button
										variant="ghost"
										onClick={() => setShowPassword((prev) => !prev)}
									>
										{showPassword ? <ViewIcon /> : <ViewOffIcon />}
									</Button>
								</InputRightElement>
							</InputGroup>
						</FormControl>
						<FormControl id="confirmPassword" isRequired>
							<FormLabel>ยืนยันรหัสผ่าน</FormLabel>
							<InputGroup>
								<Input
									type={showPassword ? "text" : "password"}
									value={confirmPassword}
									onChange={(e) => setConfirmPassword(e.target.value)}
								/>
								<InputRightElement h="full">
									<Button
										variant="ghost"
										onClick={() => setShowPassword((prev) => !prev)}
									>
										{showPassword ? <ViewIcon /> : <ViewOffIcon />}
									</Button>
								</InputRightElement>
							</InputGroup>
						</FormControl>
						<Stack spacing={10} pt={2}>
							<Button
								loadingText="Submitting"
								size="lg"
								bg="blue.400"
								color="white"
								_hover={{
									bg: "blue.500",
								}}
								onClick={handleSubmit}
							>
								สมัคร
							</Button>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Flex>
	);
}
