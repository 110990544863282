import React from "react";
import { Box, Image, Text, Heading, Flex, IconButton } from "@chakra-ui/react";
import Slider from "react-slick";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

import slide_1 from "../../images/home_page/carousels/slide_2.jpg";
import slide_2 from "../../images/home_page/carousels/slide_4.jpg";
import slide_4 from "../../images/restaurant/res_slide_11.jpg";
import slide_5 from "../../images/restaurant/res_slide_3.jpg";
import slide_6 from "../../images/home_page/carousels/slide_5.jpg";
import slide_7 from "../../images/home_page/carousels/slide_6.jpg";

const carouselSettings = {
	dots: true,
	arrows: false,
	fade: true,
	infinite: true,
	autoplay: true,
	speed: 500,
	autoplaySpeed: 5000,
	slidesToShow: 1,
	slidesToScroll: 1,
};

const carouselItems = [
	{
		src: slide_5,
		alt: "ระบบ QR Menu",
		title: "ระบบ QR Menu",
		description: "ให้ลูกค้าของคุณสัมผัสการใช้งานที่ทันสมัย",
	},
	{
		src: slide_2,
		alt: "ลดการใช้กระดาษ",
		title: "ลดการใช้กระดาษ",
		description: "ประหยัดเวลาและลดความผิดพลาดด้วยระบบดิจิทัลที่ทันสมัย",
	},
	{
		src: slide_4,
		alt: "เพิ่มยอดขาย",
		title: "เพิ่มยอดขาย",
		description: "ช่วยให้การจัดการออเดอร์รวดเร็วและมีประสิทธิภาพ",
	},
	{
		src: slide_1,
		alt: "ปรับเปลี่ยนเมนูได้ตลอด",
		title: "ปรับเปลี่ยนเมนูได้ตลอด",
		description: "มอบความยืดหยุ่นในการอัปเดตเมนูตามความต้องการ",
	},
	{
		src: slide_6,
		alt: "ใช้งานง่าย",
		title: "ใช้งานง่าย",
		description: "ผ่านแอพพลิเคชั่นทั้งมือถือและแท็บเล็ต",
	},
	{
		src: slide_7,
		alt: "ไม่ต้องเสียเวลานับยอด",
		title: "ไม่ต้องเสียเวลานับยอด",
		description:
			"หยุดที่จะต้องมานั่งนับว่าวันนี้ขายได้เท่าไหร่ เพราะระบบสรุปให้แล้ว",
	},
];

const CarouselsHome = () => {
	const [slider, setSlider] = React.useState(null);

	return (
		<Box
			position="relative"
			height={{ base: "400px", md: "500px", lg: "580px", xl: "685px" }}
			width="full"
			overflow="hidden"
			_hover={{ ".carousel-arrow": { opacity: 1 } }}
		>
			<link
				rel="stylesheet"
				type="text/css"
				charSet="UTF-8"
				href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
			/>
			<link
				rel="stylesheet"
				type="text/css"
				href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
			/>
			<Box
				className="carousel-arrow"
				position="absolute"
				left={{ base: "20px", md: "50px" }}
				top="50%"
				transform="translate(0%, -50%)"
				zIndex={2}
				opacity={0}
				transition="opacity 0.3s"
			>
				<IconButton
					aria-label="left-arrow"
					colorScheme="messenger"
					borderRadius="full"
					onClick={() => slider?.slickPrev()}
					icon={<BsChevronLeft size={40} />}
					bg={"gray.400"}
					w={{ base: "50px", md: "80px" }}
					h={{ base: "50px", md: "80px" }}
					_hover={{ bg: "orange.300" }}
				/>
			</Box>
			<Box
				className="carousel-arrow"
				position="absolute"
				right={{ base: "20px", md: "50px" }}
				top="50%"
				transform="translate(0%, -50%)"
				zIndex={2}
				opacity={0}
				transition="opacity 0.3s"
			>
				<IconButton
					aria-label="right-arrow"
					colorScheme="messenger"
					borderRadius="full"
					onClick={() => slider?.slickNext()}
					icon={<BsChevronRight size={40} />}
					bg={"gray.200"}
					w={{ base: "50px", md: "80px" }}
					h={{ base: "50px", md: "80px" }}
					_hover={{ bg: "orange.300" }}
				/>
			</Box>
			<Slider {...carouselSettings} ref={(slider) => setSlider(slider)}>
				{carouselItems.map((item, index) => (
					<Box
						key={index}
						height={{ base: "400px", md: "500px", lg: "580px", xl: "685px" }}
						position="relative"
					>
						<Image
							src={item.src}
							alt={item.alt}
							objectFit="cover"
							width="100%"
							height="100%"
							zIndex={1}
						/>
						<Flex
							position="absolute"
							bottom="0"
							width="full"
							// p={{ base: "30px 50px 15px 50px", md: "50px 120px 30px 100px" }}
							p={{ base: 3, md: 4, lg: 5, xl: 6 }}
							flexDirection="column"
							// justifyContent="flex-end"
							// alignItems="flex-end"
							textAlign={{ base: "center", md: "right" }}
							textShadow={"0 0 20px #00000049"}
							color="orange.300"
							zIndex={2}
							bgColor={"#0000008f"}
						>
							<Heading
								size={{ base: "lg", md: "xl", lg: "2xl", xl: "3xl" }}
								mb={{ base: 0, md: 4 }}
							>
								{item.title}
							</Heading>
							<Text
								fontSize={{ base: "lg", md: "xl" }}
								fontWeight={"500"}
								display={{ base: "none", md: "block" }}
							>
								{item.description}
							</Text>
						</Flex>
					</Box>
				))}
			</Slider>
		</Box>
	);
};

export default CarouselsHome;
