import React, { useContext } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import {
	Box,
	Flex,
	Text,
	IconButton,
	Button,
	Stack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	useColorModeValue,
	useBreakpointValue,
	useDisclosure,
	Image,
	Container,
	SimpleGrid,
	Link,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import logo from "../images/logo250.png";
import TeamContext from "../hooks/TeamContext";

export default function TopTeamMenuBar() {
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { teamUser, TeamLogout } = useContext(TeamContext);

	return (
		<>
			<Box>
				<Box
					py={1}
					borderBottom={1}
					borderStyle={"solid"}
					borderColor={useColorModeValue("gray.200", "gray.900")}
				>
					<Container maxW="container.xl">
						<Flex
							bg={useColorModeValue("white", "gray.800")}
							color={useColorModeValue("gray.600", "white")}
							minH={"60px"}
							py={{ base: 2 }}
							align={"center"}
						>
							<Flex
								flex={{ base: 1, md: "auto" }}
								ml={{ base: -2 }}
								display={{ base: "flex", md: "none" }}
							>
								<IconButton
									onClick={onOpen}
									icon={<HamburgerIcon w={5} h={5} />}
									variant={"ghost"}
									aria-label={"Toggle Navigation"}
								/>
							</Flex>
							<Flex
								flex={{ base: 1 }}
								justify={{ base: "center", md: "start" }}
							>
								<Text
									textAlign={useBreakpointValue({ base: "center", md: "left" })}
									fontFamily={"heading"}
									color={useColorModeValue("gray.800", "white")}
								>
									<Link onClick={() => navigate("/")}>
										<Image src={logo} h={{ base: 7, md: 9 }} />
									</Link>
								</Text>

								<Flex display={{ base: "none", md: "flex" }} ml={10}>
									<DesktopNav teamUser={teamUser} />
								</Flex>
							</Flex>

							<Stack
								flex={{ base: 1, md: 0 }}
								justify={"flex-end"}
								direction={"row"}
								spacing={6}
							>
								{teamUser ? (
									<Button
										fontSize={"sm"}
										fontWeight={600}
										variant={"link"}
										color={"gray.600"}
										onClick={TeamLogout}
									>
										ออกจากระบบ
									</Button>
								) : (
									<>
										<Button
											display={{ base: "none", md: "inline-flex" }}
											fontSize={"sm"}
											fontWeight={400}
											variant={"link"}
											onClick={() => navigate("/team/register")}
											_hover={{ cursor: "pointer" }}
										>
											สมัครสมาชิก
										</Button>
										<Button
											size={{ base: "sm", md: "md" }}
											fontSize={"sm"}
											fontWeight={600}
											color={"white"}
											bg={"pink.400"}
											_hover={{ bg: "pink.300" }}
											onClick={() => navigate("/team/login")}
										>
											ลงชื่อเข้าใช้
										</Button>
									</>
								)}
							</Stack>
						</Flex>
					</Container>
				</Box>
			</Box>
			<Stack bgColor={"gray.100"}>
				<Container maxW="container.xl" py={10} minH={"calc(100vh - 70px)"}>
					<Outlet />
				</Container>
			</Stack>

			<MobileNav isOpen={isOpen} onClose={onClose} teamUser={teamUser} />
		</>
	);
}

const MobileNav = ({ isOpen, onClose, teamUser }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const linkColor = useColorModeValue("gray.600", "gray.200");
	const highlightColor = useColorModeValue("cyan.700", "cyan.300");

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader textAlign={"center"}>Menu</ModalHeader>
				<ModalBody>
					<SimpleGrid
						columns={{ base: 1, sm: 2 }}
						spacing={4}
						bg={useColorModeValue("white", "gray.800")}
						p={4}
						pt={0}
						pb={6}
					>
						{NAV_ITEMS.map((navItem) => (
							<MobileNavItem
								key={navItem.label}
								{...navItem}
								onClose={onClose}
							/>
						))}
						{teamUser ? (
							<Button
								boxShadow={"0px 0px 1px 0px gray"}
								bgColor={"white"}
								textAlign={"center"}
								variant={"link"}
								onClick={() => {
									navigate("/team/profile");
									onClose();
								}}
							>
								<Box
									py={2}
									fontWeight={location.pathname === "/team/profile" ? 800 : 500}
									color={
										location.pathname === "/team/profile"
											? highlightColor
											: linkColor
									}
								>
									หน้าโปรไฟล์
								</Box>
							</Button>
						) : (
							<Button
								boxShadow={"0px 0px 1px 0px gray"}
								bgColor={"white"}
								textAlign={"center"}
								variant={"link"}
								onClick={() => {
									navigate("/team/register");
									onClose();
								}}
							>
								<Box
									py={2}
									fontWeight={
										location.pathname === "/team/register" ? 800 : 500
									}
									color={
										location.pathname === "/team/register"
											? highlightColor
											: linkColor
									}
								>
									สมัครสมาชิก
								</Box>
							</Button>
						)}
					</SimpleGrid>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

const MobileNavItem = ({ label, href, onClose }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const linkColor = useColorModeValue("gray.600", "gray.200");
	const highlightColor = useColorModeValue("cyan.700", "cyan.300");

	const handleClick = () => {
		navigate(href);
		onClose();
	};

	return (
		<Button
			boxShadow={"0px 0px 1px 0px gray"}
			bgColor={"white"}
			textAlign={"center"}
			onClick={handleClick}
		>
			<Box py={2}>
				<Text
					fontWeight={location.pathname === href ? 800 : 500}
					color={location.pathname === href ? highlightColor : linkColor}
				>
					{label}
				</Text>
			</Box>
		</Button>
	);
};

const DesktopNav = ({ teamUser }) => {
	const linkColor = useColorModeValue("gray.600", "gray.200");
	const highlightColor = useColorModeValue("cyan.700", "cyan.300");
	const navigate = useNavigate();
	const location = useLocation();

	const handleNavigation = (href) => {
		if (href) navigate(href);
	};

	return (
		<Stack direction={"row"} spacing={4}>
			{NAV_ITEMS.map((navItem) => (
				<Box key={navItem.label}>
					<Text
						p={2}
						fontSize={"sm"}
						fontWeight={location.pathname === navItem.href ? 800 : 500}
						color={
							location.pathname === navItem.href ? highlightColor : linkColor
						}
						onClick={() => handleNavigation(navItem.href)}
						_hover={{
							textDecoration: "none",
							color: highlightColor,
						}}
						cursor="pointer"
					>
						{navItem.label}
					</Text>
				</Box>
			))}
			{teamUser && (
				<Button
					fontSize={"sm"}
					fontWeight={location.pathname === "/team/profile" ? 800 : 500}
					variant={"link"}
					color={
						location.pathname === "/team/profile" ? highlightColor : "gray.600"
					}
					onClick={() => navigate("/team/profile")}
					_hover={{ cursor: "pointer", color: highlightColor }}
				>
					หน้าโปรไฟล์
				</Button>
			)}
		</Stack>
	);
};

const NAV_ITEMS = [
	{ label: "แนะนำโปรแกรม", href: "/team" },
	{ label: "การสร้างรายได้", href: "/team/revenue" },
];
