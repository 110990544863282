import React, { useEffect, useState } from "react";
import socket from "../../../config/socket";
import {
	Box,
	Button,
	Table,
	Tbody,
	Tr,
	Td,
	Spinner,
	useColorModeValue,
	Spacer,
	Flex,
	Text,
	ScaleFade,
	Container,
} from "@chakra-ui/react";

function HistoryOrder({ shopValueId }) {
	const bgColor = useColorModeValue("gray.50", "gray.800");
	const [orderList, setOrderList] = useState([]);
	const [lastLoad, setLastLoad] = useState();
	const [showLoadMore, setShowLoadMore] = useState(true);
	const [loading, setLoading] = useState(true);
	const [showData, setShowData] = useState(true);
	const loadMore = 5;

	const [minCallStaffId, setMinCallStaffId] = useState(null);

	useEffect(() => {
		// Listen for order_list updates
		const handleOrderList = (data) => {
			// console.log("order_list:", data);
			if (data.length === 0) {
				setShowData(false);
			}

			// เพิ่มการกรองและหาค่า call_staff_id ที่น้อยที่สุด
			const callStaffIds = data
				.filter((item) => item.call_staff_id !== undefined)
				.map((item) => item.call_staff_id);
			if (callStaffIds.length > 0) {
				const minCallStaffIdNow = Math.min(...callStaffIds);
				setMinCallStaffId(minCallStaffIdNow);
			}

			setOrderList((prevOrderList) => [...prevOrderList, ...data]);
			if (data.length < loadMore) {
				setShowLoadMore(false);
			}
			setLoading(false);
		};

		// Listen for new order updates and prepend
		const handleUpdateOrderList = (data) => {
			// console.log("update_order_list:", data);
			const newData = Array.isArray(data) ? data : [data];
			setOrderList((prevOrderList) => [...newData, ...prevOrderList]);
		};

		socket.on("order_list", handleOrderList);
		socket.on("update_order_list", handleUpdateOrderList);

		// Clean up socket listeners on component unmount
		return () => {
			socket.off("order_list", handleOrderList);
			socket.off("update_order_list", handleUpdateOrderList);
		};
	}, []);

	useEffect(() => {
		if (shopValueId && loading) {
			const fromId = lastLoad || null; // Ensure fromId is null if it's undefined
			const limit = loadMore;
			// console.log("get_order_list:", shopValueId, fromId, limit);
			socket.emit("get_order_list", shopValueId, fromId, limit, minCallStaffId);
		}
	}, [shopValueId, lastLoad, loading, minCallStaffId]);

	const thaiDateTime = (dateTimeString) => {
		const dateTime = new Date(dateTimeString);
		const options = {
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
			hour12: false,
		};
		return dateTime.toLocaleDateString("th-TH", options);
	};

	// Function to load more rows
	const loadMoreRows = () => {
		if (orderList.length > 0) {
			const lastOrderId = orderList[orderList.length - 1].order_id;
			setLastLoad(lastOrderId); // Update lastLoad with the last order's ID
		}
		setLoading(true); // Trigger loading state for fetching more orders
	};

	const formatValue = (value) => {
		return value.endsWith(".00") ? parseInt(value) : value;
	};

	return (
		<>
			<Container maxW="container.sm" px={0}>
				{!showData ? (
					<Box mt={10} textAlign={"center"}>
						ยังไม่มีออเดอร์
					</Box>
				) : (
					orderList.map((order, index) => (
						<>
							{order.call_staff_unit_id ? (
								<ScaleFade initialScale={0.5} in={true} key={index}>
									<Box
										key={index}
										borderRadius={"md"}
										bgColor={"orange.50"}
										color={"cyan.700"}
										pos={"relative"}
										fontWeight="normal"
										mb={4}
									>
										<Flex
											py={2}
											px={4}
											bgColor={"orange.100"}
											borderTopRadius={"md"}
										>
											<Text>{order.unit_name}</Text>
											<Spacer />
											<Text fontSize="sm" fontWeight="300">
												{thaiDateTime(order.call_staff_datetime)}
											</Text>
										</Flex>
										<Box
											py={2}
											textAlign={"center"}
											fontWeight={"300"}
											color={"gray.500"}
										>
											เรียกพนักงาน
										</Box>
									</Box>
								</ScaleFade>
							) : (
								<ScaleFade initialScale={0.5} in={true} key={index}>
									<Box
										key={index}
										borderRadius={"md"}
										bgColor={bgColor}
										color={"cyan.700"}
										pos={"relative"}
										fontWeight="normal"
										mb={4}
									>
										<Flex
											py={2}
											px={4}
											bgColor={"gray.100"}
											borderTopRadius={"md"}
										>
											<Text>{order.unit_name}</Text>
											<Spacer />
											<Text fontSize="sm" fontWeight="300">
												{thaiDateTime(order.order_created_datetime)}
											</Text>
										</Flex>

										<Table size={{ base: "sm", md: "md" }}>
											<Tbody fontWeight={"300"} color={"gray.500"}>
												{order.order_details && (
													<>
														{order.order_details.split(";").map((detail, i) => {
															const [productName, price, quantity] =
																detail.split(":");
															const countPrice =
																formatValue(parseFloat(price).toFixed(2)) *
																parseInt(quantity);
															return (
																<Tr key={i}>
																	<Td whiteSpace={"normal"} w={"45%"}>
																		{productName}
																	</Td>
																	<Td textAlign={"center"} w={"35%"}>
																		{formatValue(parseFloat(price).toFixed(2))}{" "}
																		x {quantity}
																	</Td>
																	<Td textAlign={"right"}>{countPrice}</Td>
																</Tr>
															);
														})}
														<Tr>
															<Td
																border={"none"}
																colSpan={3}
																textAlign={"right"}
															>
																<Text as="u">
																	{formatValue(
																		order.order_details
																			.split(";")
																			.reduce((total, detail) => {
																				const [, price, quantity] =
																					detail.split(":");
																				return (
																					total +
																					parseFloat(price) * parseInt(quantity)
																				);
																			}, 0)
																			.toFixed(2)
																	)}
																</Text>
															</Td>
														</Tr>
													</>
												)}
											</Tbody>
										</Table>
									</Box>
								</ScaleFade>
							)}
						</>
					))
				)}
				{showLoadMore && (
					<Box textAlign={"center"}>
						<Button
							onClick={loadMoreRows}
							mt={4}
							colorScheme="gray"
							size={{ base: "xs", md: "sm" }}
							fontWeight={"400"}
						>
							{loading ? <Spinner size="sm" /> : "เพิ่มเติม"}
						</Button>
					</Box>
				)}
			</Container>
		</>
	);
}

export default HistoryOrder;
