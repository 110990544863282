import React, { useState } from "react";
import {
	Container,
	Heading,
	Text,
	Box,
	Link,
	ListItem,
	UnorderedList,
	Flex,
} from "@chakra-ui/react";

const PrivacyPolicy = () => {
	const [language, setLanguage] = useState("Thai");

	const toggleLanguage = () => {
		setLanguage((prevLanguage) =>
			prevLanguage === "Thai" ? "English" : "Thai"
		);
	};

	return (
		<Box bgColor={"gray.50"}>
			<Container maxW="container.lg" py={{ base: 10, md: 14, xl: 20 }}>
				<Box
					boxShadow={"sm"}
					bgColor={"white"}
					px={{ base: 10, md: 20 }}
					py={{ base: 8, md: 14 }}
				>
					<Flex justifyContent="flex-end" fontSize={"sm"} gap={1}>
						{language === "English" ? (
							<>
								<Link onClick={toggleLanguage} color={"blue.400"} px={0}>
									Thai
								</Link>
								/<Text>English</Text>
							</>
						) : (
							<>
								<Text>Thai</Text>/
								<Link onClick={toggleLanguage} color={"blue.400"} px={0}>
									English
								</Link>
							</>
						)}
					</Flex>

					{language === "Thai" ? (
						<ThaiPrivacyPolicy />
					) : (
						<EnglishPrivacyPolicy />
					)}
				</Box>
			</Container>
		</Box>
	);
};

const ThaiPrivacyPolicy = () => {
	return (
		<>
			<Heading as="h1" size="md" mb={5} textAlign={"center"}>
				นโยบายความเป็นส่วนตัว
			</Heading>
			<Text mb={5}>ปรับปรุงล่าสุด: 8 กันยายน พ.ศ.2567</Text>

			<Text mb={5}>
				บริการเว็บไซต์และโมบายแอพพลิเคชั่นของเรา
				ให้ความสำคัญกับความเป็นส่วนตัวของผู้ใช้งาน
				เอกสารนี้ระบุวิธีการที่เรารวบรวมและปกป้องข้อมูลส่วนบุคคลที่ได้จากการใช้งานระบบของเรา
			</Text>

			<Box mb={8}>
				<Heading as="h2" size="md" mb={3}>
					1. ข้อมูลที่เราเก็บรวบรวม
				</Heading>
				<UnorderedList>
					<ListItem>ข้อมูลลูกค้า: ชื่อ อีเมล รหัสผ่าน ชื่อร้าน</ListItem>
					<ListItem>
						ข้อมูลร้านค้าและธุรกรรม: รายการสินค้า ประวัติการขาย
						ข้อมูลการใช้งานระบบ
					</ListItem>
				</UnorderedList>
			</Box>

			<Box mb={8}>
				<Heading as="h2" size="md" mb={3}>
					2. การใช้ข้อมูลที่เก็บรวบรวม
				</Heading>
				<Text>ข้อมูลที่เราเก็บรวบรวมจะถูกนำไปใช้ในกรณีต่าง ๆ ดังนี้:</Text>
				<UnorderedList>
					<ListItem>
						ประมวลผลคำสั่งซื้อพร้อมนำเสนอต่อลูกค้าในรูปแบบสถิติ
					</ListItem>
					<ListItem>วิเคราะห์และพัฒนาประสบการณ์การใช้งานของผู้ใช้</ListItem>
					<ListItem>จัดการและติดตามประวัติการขาย</ListItem>
					<ListItem>ให้การสนับสนุนลูกค้าและปรับปรุงบริการ</ListItem>
					<ListItem>ปฏิบัติตามข้อกำหนดทางกฎหมายและความปลอดภัย</ListItem>
				</UnorderedList>
			</Box>

			<Box mb={8}>
				<Heading as="h2" size="md" mb={3}>
					3. การแบ่งปันข้อมูลกับบุคคลที่สาม
				</Heading>
				<Text>
					เราอาจแบ่งปันข้อมูลของคุณกับบุคคลที่สามเฉพาะในกรณีที่จำเป็นต่อการให้บริการ
					เช่น:
				</Text>
				<UnorderedList>
					<ListItem>ผู้ให้บริการระบบคลาวด์และเทคโนโลยีสนับสนุน</ListItem>
					<ListItem>ผู้ให้บริการวิเคราะห์ข้อมูลเพื่อการพัฒนาบริการ</ListItem>
				</UnorderedList>
			</Box>

			<Box mb={8}>
				<Heading as="h2" size="md" mb={3}>
					4. การรักษาความปลอดภัยของข้อมูล
				</Heading>
				<Text>
					เรามีมาตรการความปลอดภัยเพื่อปกป้องข้อมูลส่วนบุคคลของคุณจากการเข้าถึงหรือใช้โดยไม่ได้รับอนุญาต
					เช่น การเข้ารหัสข้อมูล การควบคุมการเข้าถึง และการสำรองข้อมูล
				</Text>
			</Box>

			<Box mb={8}>
				<Heading as="h2" size="md" mb={3}>
					5. สิทธิของผู้ใช้
				</Heading>
				<Text>
					คุณมีสิทธิ์ในการเข้าถึง แก้ไข
					หรือลบข้อมูลส่วนบุคคลหรือข้อมูลร้านของคุณที่เราเก็บรวบรวม
				</Text>
			</Box>

			<Box mb={8}>
				<Heading as="h2" size="md" mb={3}>
					6. ระยะเวลาการเก็บรักษาข้อมูล
				</Heading>
				<Text>
					เราจะเก็บข้อมูลส่วนบุคคลของคุณไว้ตราบเท่าที่จำเป็นเพื่อวัตถุประสงค์ในการให้บริการ
					และตามระยะเวลาที่กฎหมายกำหนด
				</Text>
			</Box>

			<Box mb={8}>
				<Heading as="h2" size="md" mb={3}>
					7. การเปลี่ยนแปลงนโยบาย
				</Heading>
				<Text>
					นโยบายความเป็นส่วนตัวนี้อาจมีการเปลี่ยนแปลงตามความเหมาะสม
					หากมีการเปลี่ยนแปลงใด ๆ
					เราจะแจ้งให้คุณทราบผ่านทางแอปพลิเคชันหรือเว็บไซต์ของเรา
				</Text>
			</Box>

			<Box mb={8}>
				<Heading as="h2" size="md" mb={3}>
					8. การติดต่อเรา
				</Heading>
				<Text mb={5}>
					หากคุณมีคำถามเกี่ยวกับนโยบายความเป็นส่วนตัวนี้หรือวิธีการจัดการข้อมูลของคุณ
					กรุณาติดต่อเราได้ที่:
				</Text>
				<Text>
					อีเมล:{" "}
					<Link href="mailto:sevscan.info@gmail.com" color="blue.500">
						sevscan.info@gmail.com
					</Link>
				</Text>
			</Box>
		</>
	);
};

const EnglishPrivacyPolicy = () => {
	return (
		<>
			<Heading as="h1" size="md" mb={5} textAlign={"center"}>
				Privacy Policy
			</Heading>
			<Text mb={5}>Last updated: September 8, 2024</Text>

			<Text mb={5}>
				Our website and mobile application services prioritize the privacy of
				our users. This document outlines how we collect and protect personal
				information obtained through the use of our system.
			</Text>

			<Box mb={8}>
				<Heading as="h2" size="md" mb={3}>
					1. Information We Collect
				</Heading>
				<UnorderedList>
					<ListItem>
						Customer Information: Name, Email, Password, Store Name
					</ListItem>
					<ListItem>
						Store and Transaction Information: Product Listings, Sales History,
						System Usage Data
					</ListItem>
				</UnorderedList>
			</Box>

			<Box mb={8}>
				<Heading as="h2" size="md" mb={3}>
					2. How We Use the Collected Information
				</Heading>
				<Text>
					The information we collect will be used in the following cases:
				</Text>
				<UnorderedList>
					<ListItem>
						Processing orders and presenting data to customers in statistical
						format
					</ListItem>
					<ListItem>Analyzing and improving user experience</ListItem>
					<ListItem>Managing and tracking sales history</ListItem>
					<ListItem>Providing customer support and improving services</ListItem>
					<ListItem>Complying with legal and security requirements</ListItem>
				</UnorderedList>
			</Box>

			<Box mb={8}>
				<Heading as="h2" size="md" mb={3}>
					3. Sharing Information with Third Parties
				</Heading>
				<Text>
					We may share your information with third parties only when necessary
					for providing services, such as:
				</Text>
				<UnorderedList>
					<ListItem>
						Cloud service providers and technology support providers
					</ListItem>
					<ListItem>Data analytics providers for service improvement</ListItem>
				</UnorderedList>
			</Box>

			<Box mb={8}>
				<Heading as="h2" size="md" mb={3}>
					4. Data Security
				</Heading>
				<Text>
					We have security measures in place to protect your personal
					information from unauthorized access or use, such as data encryption,
					access controls, and backups.
				</Text>
			</Box>

			<Box mb={8}>
				<Heading as="h2" size="md" mb={3}>
					5. User Rights
				</Heading>
				<Text>
					You have the right to access, modify, or delete your personal or store
					information that we collect.
				</Text>
			</Box>

			<Box mb={8}>
				<Heading as="h2" size="md" mb={3}>
					6. Data Retention Period
				</Heading>
				<Text>
					We will retain your personal information for as long as necessary to
					provide services and as required by law.
				</Text>
			</Box>

			<Box mb={8}>
				<Heading as="h2" size="md" mb={3}>
					7. Changes to the Policy
				</Heading>
				<Text>
					This privacy policy may be updated as necessary. If any changes are
					made, we will notify you through our application or website.
				</Text>
			</Box>

			<Box mb={8}>
				<Heading as="h2" size="md" mb={3}>
					8. Contact Us
				</Heading>
				<Text mb={5}>
					If you have any questions about this privacy policy or how your
					information is handled, please contact us at:
				</Text>
				<Text>
					Email:{" "}
					<Link href="mailto:sevscan.info@gmail.com" color="blue.500">
						sevscan.info@gmail.com
					</Link>
				</Text>
			</Box>
		</>
	);
};

export default PrivacyPolicy;
