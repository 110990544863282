import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { API_BASE_URL } from "../config/apiConfig";
import {
	SimpleGrid,
	Flex,
	Box,
	chakra,
	Button,
	Badge,
	Container,
	Text,
	Image,
	ListItem,
	UnorderedList,
	Icon,
	Stack,
	HStack,
	VStack,
	Link,
	useToast,
} from "@chakra-ui/react";
import { CheckIcon, ChevronRightIcon } from "@chakra-ui/icons";
import restaurantImg from "../images/restaurant.jpg";
import condominiumImg from "../images/condominium.jpg";
import CarouselsHome from "./component/CarouselsHome";
import Testimonials from "./component/Testimonials";
import Statistics from "./component/Statistics";
import BusinessList from "./component/BusinessList";
import Hero from "./component/Hero";
import CardRegister from "./component/CardRegister";
import CardPrice from "./component/CardPrice";

import res_system_1 from "../images/pmanual/res_system_1.png";
import res_system_2 from "../images/pmanual/res_system_2.png";
import res_system_3 from "../images/pmanual/res_system_3.png";

function Home() {
	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const ref_code = queryParams.get("ref");

		if (ref_code) {
			Cookies.set("ref_code", ref_code, { expires: 30 });
		}
	}, [location]);

	const toast = useToast();
	const boxRef = useRef(null);

	const scrollToBox = () => {
		if (boxRef.current) {
			const yOffset = -50; // Adjust for the -30px
			const yPosition =
				boxRef.current.getBoundingClientRect().top +
				window.pageYOffset +
				yOffset;

			window.scrollTo({ top: yPosition, behavior: "smooth" });
		}
	};

	const [price, setPrice] = useState();
	const [priceCount, setPriceCount] = useState();
	useEffect(() => {
		const getPricePromotion = async () => {
			try {
				const response = await axios.get(
					`${API_BASE_URL}/api/marketing/price_promotion`
				);
				setPrice(response.data.results[0].price);
				setPriceCount(response.data.results[0].count);
			} catch (error) {
				toast({
					title: `ไม่สามารถติดต่อระบบได้`,
					description: `กรุณาติดต่อพนักงาน`,
					position: "top",
					isClosable: true,
					status: "error",
					variant: "top-accent",
					duration: 5000,
				});
			}
		};
		getPricePromotion();
	}, [toast]);

	return (
		<>
			<Box>
				<CarouselsHome />
				<Hero scrollToBox={scrollToBox} price={price} />
				<ProductFeature />
				<Why4Code />
				<Box ref={boxRef}>
					<BusinessList />
				</Box>
				<Statistics />
				<NotUser />
				<BusinessCategory />
				<Testimonials />
				<CardPrice
					businessType={"restaurant"}
					price={price}
					priceCount={priceCount}
				/>
				<CardRegister price={price} />
			</Box>
		</>
	);
}

const BusinessCategory = () => {
	const navigate = useNavigate();
	const sections = [
		{
			title: "ระบบจัดการออเดอร์",
			description:
				"ระบบสั่งอาหารผ่าน QR Menu เพิ่มความยืดหยุ่นในการอัปเดตเมนู ลดการใช้กระดาษ ประหยัดเวลาและลดความผิดพลาดด้วยระบบดิจิทัล ใช้งานง่าย ใช้งานได้ทันทีไม่ต้องติดตั้งอุปกรณ์เสริม ช่วยให้การจัดการออเดอร์รวดเร็วและมีประสิทธิภาพ เพิ่มยอดขายได้จริง ตอบโจทย์ความต้องการของลูกค้า มอบประสบการณ์การสั่งอาหารที่สะดวกและประทับใจ",
			badges: [
				"ร้านอาหาร",
				"สวนอาหาร",
				"ลานเบียร์",
				"ร้านคาเฟ่เบเกอรี่",
				"ร้านบุฟเฟต์",
			],
			img: restaurantImg,
			button: { text: "รายละเอียด", action: () => navigate("/restaurant") },
		},
		{
			title: "ระบบบริการสำหรับธุรกิจที่พัก",
			description:
				"หากคุณต้องการเพิ่มรายได้ให้กับกิจการด้วยการนำเสนอบริการเสริมที่มี เช่น บริการอาหาร เครื่องดื่ม ขนมขบเคี้ยว บริการสปาและนวด บริการเช่ารถ บริการทำความสะอาด บริการซักรีด บริการรับ-ส่ง ขายของที่ระลึกและอื่นๆ",
			badges: [
				"รีสอร์ท",
				"โรงแรม",
				"หอพัก",
				"วิลล่า",
				"บังกะโล",
				"โฮมสเตย์",
				"เกสต์ เฮาส์",
				"อพาร์ทเม้นต์",
				"โมเทล",
			],
			img: restaurantImg,
			button: { text: "รายละเอียด", action: () => navigate("/accommodation") },
		},
		{
			title: "ระบบขายสินค้า/บริการ",
			description:
				"สำหรับผู้ประกอบกิจการที่ทราบสถานที่ปลายทางที่จะนำส่งบริการ สามารถนำระบบไปต่อยอดบริการด้วยความสามารถปรับเปลี่ยนสินค้าหรือบริการได้อย่างยืดหยุ่น อัพเดทแบบทันที บันทึกออเดอร์ให้อัตโนมัติและแจ้งเตือนส่งตรงถึง LINE ผู้ประกอบการทันที",
			badges: [
				"ร้านค้าใน/ใกล้หอพัก",
				"ร้านซักอบรีด",
				"ร้านรับออเดอร์ขนม",
				"ธุรกิจรับหิ้วสินค้า",
				"เช่าที่นั่งร่วมกับสั่งอาหาร",
				"ธุรกิจรับเสริมสวย",
				"ร้านอาหาร/เครื่องดื่มที่มีบริการจัดส่ง",
			],
			img: condominiumImg,
			button: { text: "เร็วๆ นี้", action: () => {}, disabled: true },
		},
	];
	return (
		<Container maxW="container.xl" display={"none"}>
			<Flex
				pt={{ base: 10, md: 20 }}
				pb={{ base: 6, md: 12 }}
				direction={"column"}
				gap={{ base: 10, md: 20 }}
				bg="white"
				_dark={{ bg: "gray.800" }}
				mx="auto"
			>
				{sections.map((section, index) => (
					<SimpleGrid
						key={index}
						alignItems="center"
						columns={{ base: 1, md: 2 }}
						spacingY={{ base: 5, md: 32 }}
						spacingX={{ md: 10, lg: 24 }}
					>
						<Box
							w="full"
							h="full"
							py={48}
							bg="gray.200"
							bgImage={section.img}
							bgSize="cover"
							bgPosition="center"
							bgRepeat="no-repeat"
							borderRadius={"2em"}
							filter={"revert-layer"}
						></Box>
						<Box>
							<chakra.h2
								mb={4}
								fontSize={{ base: "2xl", md: "4xl" }}
								fontWeight="600"
								textAlign={{ base: "center", md: "left" }}
								color="cyan.700"
								_dark={{ color: "gray.400" }}
								lineHeight={{ md: "shorter" }}
							>
								{section.title}
							</chakra.h2>
							<chakra.p
								mb={{ base: 2, md: 5 }}
								textAlign={{ base: "center", sm: "left" }}
								color="gray.600"
								_dark={{ color: "gray.400" }}
								fontSize={{ md: "lg" }}
							>
								<Box mb={2}>
									{section.badges.map((badge, i) => (
										<Badge
											key={i}
											mr={2}
											fontSize={"sm"}
											fontWeight={"500"}
											colorScheme="blackAlpha"
										>
											{badge}
										</Badge>
									))}
								</Box>
								{section.description}
							</chakra.p>
							<Box textAlign={{ base: "center", md: "left" }}>
								<Button
									size={{ base: "md", md: "lg" }}
									fontWeight={"normal"}
									bgColor={"cyan.700"}
									color="white"
									_hover={{ bgColor: "cyan.800" }}
									onClick={section.button.action}
									isDisabled={section.button.disabled}
								>
									{section.button.text}
								</Button>
							</Box>
						</Box>
					</SimpleGrid>
				))}
			</Flex>
		</Container>
	);
};

const Why4Code = () => {
	return (
		<Box
			py={{ base: 10, md: 12, lg: 14, xl: 16 }}
			mx="auto"
			_dark={{ bg: "gray.700" }}
			bgGradient="linear(to-r, cyan.400, orange.200)"
		>
			<Container maxW="container.xl">
				<Box mx="auto">
					<chakra.h1
						mb={{ base: 3, md: 6 }}
						fontSize={{
							base: "3xl",
							md: "5xl",
						}}
						fontWeight="bold"
						lineHeight={{ base: 1.4, md: "none" }}
						letterSpacing={{
							base: "normal",
							md: "tight",
						}}
						color="gray.900"
						_dark={{
							color: "gray.100",
						}}
						textAlign={"center"}
					>
						<Text
							display={{
								base: "block",
								md: "inline",
							}}
							w="full"
							bgClip="text"
							bgGradient="linear(to-r, purple.500,red.500)"
							fontWeight="extrabold"
						>
							ลักษณะการนำไปใช้งาน
						</Text>
					</chakra.h1>
					<SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
						<HStack align={"top"}>
							<Box color={"green.400"} px={2}>
								<Icon as={CheckIcon} />
							</Box>
							<VStack align={"start"}>
								<Text fontWeight={600}>ร้านที่จ่ายก่อนกิน</Text>
								<Text color={"gray.600"} _dark={{ color: "gray.400" }}>
									ระบบขายหน้าร้าน POS นำไปใช้บันทึกรายการสั่งซื้อ
									สรุปภาพรวมแต่ละวัน พร้อมแสดงรายการสินค้าขายดี
								</Text>
							</VStack>
						</HStack>
						<HStack align={"top"}>
							<Box color={"green.400"} px={2}>
								<Icon as={CheckIcon} />
							</Box>
							<VStack align={"start"}>
								<Text fontWeight={600}>ร้านที่กินก่อนจ่าย</Text>
								<Text color={"gray.600"} _dark={{ color: "gray.400" }}>
									ลูกค้าสแกน QR Menu สั่งอาหารเอง ลดการสัมผัส
									เหมาะสำหรับร้านที่มีพนักงานจำกัด
								</Text>
							</VStack>
						</HStack>
						<HStack align={"top"}>
							<Box color={"green.400"} px={2}>
								<Icon as={CheckIcon} />
							</Box>
							<VStack align={"start"}>
								<Text fontWeight={600}>แบบผสมผสานการใช้งาน</Text>
								<Text color={"gray.600"} _dark={{ color: "gray.400" }}>
									พนักงานรับออเดอร์ก่อนและแนะนำลูกค้าหากต้องการอาหารเพิ่มเติมสามารถสแกนสั่งเองได้
								</Text>
							</VStack>
						</HStack>
					</SimpleGrid>
				</Box>
			</Container>
		</Box>
	);
};

const ProductFeature = () => {
	const navigate = useNavigate();

	return (
		<Container maxW="container.xl" py={20}>
			<Flex w="auto" justifyContent="center" alignItems="center">
				<Box
					mx="auto"
					bg="white"
					_dark={{
						bg: "gray.800",
					}}
				>
					<Box textAlign={"center"}>
						<chakra.p
							fontSize={{
								base: "3xl",
								sm: "4xl",
							}}
							lineHeight="8"
							fontWeight="extrabold"
							letterSpacing="tight"
							_light={{
								color: "gray.900",
							}}
						>
							ฟังก์ชั่นการใช้งาน
						</chakra.p>
						<Flex
							align="center"
							justify="center"
							direction="row"
							wrap="wrap"
							gap={2}
							fontSize={"lg"}
							color="gray.500"
							mt={2}
						>
							<Box>สมัครใช้งาน</Box>
							<ChevronRightIcon color={"gray.400"} fontSize={"xl"} />
							<Box>ลงสินค้า</Box>
							<ChevronRightIcon color={"gray.400"} fontSize={"xl"} />
							<Box>ติดตั้ง QR Menu</Box>
							<ChevronRightIcon color={"gray.400"} fontSize={"xl"} />
							<Box>ติดตั้งแอพ</Box>
							<ChevronRightIcon color={"gray.400"} fontSize={"xl"} />
							<Box>พร้อมใช้งาน</Box>
						</Flex>
					</Box>
					<Box
						bg="white"
						_dark={{
							bg: "gray.800",
						}}
						mx="auto"
						mt={10}
					>
						<SimpleGrid
							p={{ base: 2, md: 10 }}
							alignItems="center"
							columns={{
								base: 1,
								md: 2,
							}}
							flexDirection="column-reverse"
							spacingY={{
								base: 10,
								md: 32,
							}}
							spacingX={{
								base: 10,
								md: 24,
							}}
							borderBottom={"1px solid #e4e4e4"}
						>
							<Box
								order={{
									base: "initial",
									md: 2,
								}}
							>
								<chakra.h2
									mb={4}
									fontSize={{
										base: "2xl",
										md: "4xl",
									}}
									fontWeight="extrabold"
									letterSpacing="tight"
									textAlign={{
										base: "center",
										md: "left",
									}}
									color="cyan.600"
									_dark={{
										color: "gray.400",
									}}
									lineHeight={{
										md: "shorter",
									}}
								>
									แอดมินจัดการร้าน
								</chakra.h2>
								<chakra.p
									mb={5}
									color="gray.600"
									_dark={{
										color: "gray.400",
									}}
									fontSize={{
										md: "lg",
									}}
								>
									ส่วนจัดการข้อมูลหลังบ้านสำหรับปรับแต่งรายละเอียดต่างๆ
									<UnorderedList pl={{ base: 2, md: 10 }}>
										<ListItem>สถิติข้อมูลการขาย</ListItem>
										<ListItem>ปรับแต่งชื่อหมวดหมู่สินค้า</ListItem>
										<ListItem>ปรับแต่งรายละเอียดสินค้า</ListItem>
										<ListItem>ปรับเปลี่ยนชื่อร้าน โลโก้ร้าน</ListItem>
										<ListItem>โหลด QR Menu พร้อมตัวอย่าง</ListItem>
									</UnorderedList>
								</chakra.p>
							</Box>
							<Box w="full" h="full">
								<Image src={res_system_1} boxSize="100%" objectFit="cover" />
							</Box>
						</SimpleGrid>
						<SimpleGrid
							p={{ base: 2, md: 10 }}
							alignItems="center"
							columns={{
								base: 1,
								md: 2,
							}}
							spacingY={{
								base: 10,
								md: 32,
							}}
							spacingX={{
								base: 10,
								md: 24,
							}}
							borderBottom={"1px solid #e4e4e4"}
						>
							<Box>
								<chakra.h2
									mb={4}
									fontSize={{
										base: "2xl",
										md: "4xl",
									}}
									fontWeight="extrabold"
									letterSpacing="tight"
									textAlign={{
										base: "center",
										md: "left",
									}}
									color="orange.300"
									_dark={{
										color: "gray.400",
									}}
									lineHeight={{
										md: "shorter",
									}}
								>
									ใช้งาน QR Menu
								</chakra.h2>
								<chakra.p
									mb={5}
									color="gray.600"
									_dark={{
										color: "gray.400",
									}}
									fontSize={{
										md: "lg",
									}}
								>
									<UnorderedList pl={{ base: 2, md: 10 }}>
										<ListItem>ลูกค้าสั่งสินค้าได้ด้วยตัวเอง</ListItem>
										<ListItem>เพิ่มโอกาสในการสั่งซื้อ</ListItem>
										<ListItem>ลดการยืนรอรับออเดอร์ของพนักงาน</ListItem>
										<ListItem>เช็ครายการสั่งซื้อทั้งหมดก่อนจ่ายเงิน</ListItem>
										<ListItem>กดเรียกพนักงานผ่านระบบ</ListItem>
									</UnorderedList>
								</chakra.p>
							</Box>
							<Box w="full" h="full">
								<Image src={res_system_2} boxSize="100%" objectFit="cover" />
							</Box>
						</SimpleGrid>
						<SimpleGrid
							p={{ base: 2, md: 10 }}
							alignItems="center"
							columns={{
								base: 1,
								md: 2,
							}}
							flexDirection="column-reverse"
							spacingY={{
								base: 10,
								md: 32,
							}}
							spacingX={{
								base: 10,
								md: 24,
							}}
						>
							<Box
								order={{
									base: "initial",
									md: 2,
								}}
							>
								<chakra.h2
									mb={4}
									fontSize={{
										base: "2xl",
										md: "4xl",
									}}
									fontWeight="extrabold"
									letterSpacing="tight"
									textAlign={{
										base: "center",
										md: "left",
									}}
									color="pink.300"
									_dark={{
										color: "gray.400",
									}}
									lineHeight={{
										md: "shorter",
									}}
								>
									จัดการออเดอร์ผ่านแอพ
								</chakra.h2>
								<chakra.p
									mb={5}
									color="gray.600"
									_dark={{
										color: "gray.400",
									}}
									fontSize={{
										md: "lg",
									}}
								>
									<UnorderedList pl={{ base: 2, md: 10 }}>
										<ListItem>แจ้งเตือนออเดอร์</ListItem>
										<ListItem>แสดงรายการสั่งซื้อ</ListItem>
										<ListItem>
											เพิ่มออเดอร์ในกรณีที่ลูกค้าไม่สะดวกสั่งเอง
										</ListItem>
										<ListItem>เช็คยอดสั่งซื้อรวม</ListItem>
										<ListItem>เคลียร์ออเดอร์เพื่อรองรับลูกค้าใหม่</ListItem>
									</UnorderedList>
								</chakra.p>
							</Box>
							<Box w="full" h="full">
								<Image src={res_system_3} boxSize="100%" objectFit="cover" />
							</Box>
						</SimpleGrid>
					</Box>
				</Box>
			</Flex>
			<Box textAlign={"center"} mt={4}>
				<Link px={10} fontWeight={"400"} onClick={() => navigate("/pmanual")}>
					ฟังก์ชั่นทั้งหมด
				</Link>
			</Box>
		</Container>
	);
};

const NotUser = () => {
	const Feature = (props) => {
		return (
			<Flex>
				<Flex shrink={0}>
					<Flex
						alignItems="center"
						justifyContent="center"
						h={12}
						w={12}
						rounded="md"
						_light={{
							bg: "brand.500",
						}}
						color="white"
						bgColor="cyan.700"
					>
						<Icon
							boxSize={6}
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							aria-hidden="true"
						>
							{props.icon}
						</Icon>
					</Flex>
				</Flex>
				<Box ml={4}>
					<chakra.dt
						fontSize="lg"
						fontWeight="medium"
						lineHeight="6"
						_light={{
							color: "gray.900",
						}}
					>
						{props.title}
					</chakra.dt>
					<chakra.dd
						mt={2}
						color="gray.500"
						_dark={{
							color: "gray.400",
						}}
					>
						{props.children}
					</chakra.dd>
				</Box>
			</Flex>
		);
	};

	return (
		<Container maxW="container.xl" mt={5} display={"none"}>
			<Flex w="auto" justifyContent="center" alignItems="center">
				<Box
					py={20}
					bg="white"
					_dark={{
						bg: "gray.800",
					}}
					rounded="xl"
				>
					<Box maxW="7xl" mx="auto">
						<Box
							textAlign={{
								lg: "center",
							}}
						>
							<chakra.p
								mt={2}
								fontSize={{
									base: "3xl",
									sm: "4xl",
								}}
								lineHeight="8"
								fontWeight="extrabold"
								letterSpacing="tight"
								_light={{
									color: "gray.900",
								}}
							>
								ระบบ QR Menu นี้เหมาะกับใคร
							</chakra.p>
							<chakra.p
								mt={4}
								maxW="2xl"
								fontSize="xl"
								mx={{
									lg: "auto",
								}}
								color="gray.500"
								_dark={{
									color: "gray.400",
								}}
							>
								ตรวจสอบความต้องการเพื่อให้ตอบโจทย์สำหรับธุรกิจคุณ
							</chakra.p>
						</Box>

						<Box mt={10}>
							<Stack
								spacing={{
									base: 10,
									md: 0,
								}}
								display={{
									md: "grid",
								}}
								gridTemplateColumns={{
									md: "repeat(2,1fr)",
								}}
								gridColumnGap={{
									md: 8,
								}}
								gridRowGap={{
									md: 10,
								}}
							>
								<Feature
									title="Competitive exchange rates"
									icon={
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
										/>
									}
								>
									Lorem ipsum, dolor sit amet consectetur adipisicing elit.
									Maiores impedit perferendis suscipit eaque, iste dolor
									cupiditate blanditiis ratione.
								</Feature>

								<Feature
									title=" No hidden fees"
									icon={
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
										/>
									}
								>
									Lorem ipsum, dolor sit amet consectetur adipisicing elit.
									Maiores impedit perferendis suscipit eaque, iste dolor
									cupiditate blanditiis ratione.
								</Feature>

								<Feature
									title="Transfers are instant"
									icon={
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M13 10V3L4 14h7v7l9-11h-7z"
										/>
									}
								>
									Lorem ipsum, dolor sit amet consectetur adipisicing elit.
									Maiores impedit perferendis suscipit eaque, iste dolor
									cupiditate blanditiis ratione.
								</Feature>

								<Feature
									title="Mobile notifications"
									icon={
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
										/>
									}
								>
									Lorem ipsum, dolor sit amet consectetur adipisicing elit.
									Maiores impedit perferendis suscipit eaque, iste dolor
									cupiditate blanditiis ratione.
								</Feature>
							</Stack>
						</Box>
					</Box>
				</Box>
			</Flex>
		</Container>
	);
};

export default Home;
