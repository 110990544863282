import React, { useState } from "react";
import {
	Box,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Flex,
	Text,
	Button,
	Badge,
} from "@chakra-ui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";

const Withdraw = ({ data, rowsPerPage = 5 }) => {
	const [page, setPage] = useState(0);
	const [sortConfig, setSortConfig] = useState({
		key: null,
		direction: "asc",
	});

	const sortedData = React.useMemo(() => {
		let sortableData = [...data];
		if (sortConfig.key !== null) {
			sortableData.sort((a, b) => {
				const aValue = a[sortConfig.key] || "";
				const bValue = b[sortConfig.key] || "";

				if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
				if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
				return 0;
			});
		}
		return sortableData;
	}, [data, sortConfig]);

	// Pagination calculations
	const start = page * rowsPerPage;
	const end = start + rowsPerPage;
	const paginatedData = sortedData.slice(start, end);
	const totalPages = Math.ceil(data.length / rowsPerPage);

	const handleSort = (key) => {
		setSortConfig((prevConfig) => ({
			key,
			direction:
				prevConfig.key === key && prevConfig.direction === "asc"
					? "desc"
					: "asc",
		}));
	};

	return (
		<Box>
			<TableContainer>
				<Table variant="simple">
					<Thead bgColor={"cyan.600"}>
						<Tr>
							<Th>
								<Button
									textColor={"white"}
									px={0}
									textAlign={"left"}
									variant="ghost"
									_hover="none"
									onClick={() => handleSort("withdraw_datetime")}
									rightIcon={
										sortConfig.key === "withdraw_datetime" &&
										(sortConfig.direction === "asc" ? (
											<ChevronUpIcon />
										) : (
											<ChevronDownIcon />
										))
									}
								>
									วันที่ทำรายการ
								</Button>
							</Th>
							<Th textAlign={"right"}>
								<Button
									textColor={"white"}
									px={0}
									variant="ghost"
									_hover="none"
									onClick={() => handleSort("withdraw_value")}
									rightIcon={
										sortConfig.key === "withdraw_value" &&
										(sortConfig.direction === "asc" ? (
											<ChevronUpIcon />
										) : (
											<ChevronDownIcon />
										))
									}
								>
									จำนวน
								</Button>
							</Th>
							<Th textAlign={"right"}>
								<Button
									textColor={"white"}
									px={0}
									variant="ghost"
									_hover="none"
									onClick={() => handleSort("withdraw_status")}
									rightIcon={
										sortConfig.key === "withdraw_status" &&
										(sortConfig.direction === "asc" ? (
											<ChevronUpIcon />
										) : (
											<ChevronDownIcon />
										))
									}
								>
									สถานะ
								</Button>
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{paginatedData.length > 0 ? (
							paginatedData.map((row, index) => (
								<Tr key={index} fontSize="sm">
									<Td>
										{new Date(row.withdraw_datetime).toLocaleString("th-TH", {
											year: "numeric",
											month: "long",
											day: "numeric",
											hour: "2-digit",
											minute: "2-digit",
											second: "2-digit",
										})}
									</Td>
									<Td isNumeric>{row.withdraw_value} บาท</Td>
									<Td isNumeric>
										<Text
											color={
												row.withdraw_status === "success"
													? "green.500"
													: "blue.500"
											}
										>
											{row.withdraw_status === "success" ? (
												<Badge colorScheme="green">เรียบร้อย</Badge>
											) : (
												<Badge colorScheme="blue">กำลังดำเนินการ</Badge>
											)}
										</Text>
									</Td>
								</Tr>
							))
						) : (
							<Tr>
								<Td colSpan={3} textAlign="center" py={5}>
									<Text color="gray.500">ไม่มีข้อมูล</Text>
								</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</TableContainer>

			{data.length > 10 && (
				<Box display="flex" alignItems="center" mt={4}>
					<Flex gap={2} justifyContent="center" width="100%">
						<Button
							size="sm"
							onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
							disabled={page === 0}
						>
							ก่อนหน้า
						</Button>
						<Button size="sm" _hover={{}}>
							หน้า {page + 1} / {totalPages}
						</Button>
						<Button
							size="sm"
							onClick={() =>
								setPage((prev) => Math.min(prev + 1, totalPages - 1))
							}
							disabled={page === totalPages - 1}
						>
							ถัดไป
						</Button>
					</Flex>
				</Box>
			)}
		</Box>
	);
};

export default Withdraw;
