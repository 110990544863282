import React from "react";
import {
	Container,
	useColorModeValue,
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Flex,
	Text,
	Box,
	OrderedList,
	ListItem,
	Spacer,
	Link,
	UnorderedList,
	SimpleGrid,
	Card,
} from "@chakra-ui/react";
import EmployeeTest from "../component/manual/EmployeeTest";
import manual_pdf from "../../file/Manual.pdf";
import { useAuth } from "../../hooks/AuthProvider";

function Manual() {
	const bgColor = useColorModeValue("cyan.700", "cyan.900");
	const auth = useAuth();

	const bType = auth.user.business_type;

	return (
		<>
			<Flex display={"none"}>
				<Link href={manual_pdf} isExternal>
					คู่มือ
				</Link>
			</Flex>
			<Container mt={{ base: "auto", md: 3 }} maxW={"6xl"}>
				<Card borderRadius={"xl"} p={{ base: 3, md: 7 }}>
					<Accordion allowToggle>
						<Box
							p={4}
							bgColor={"cyan.700"}
							color={"gray.200"}
							fontWeight={"bold"}
							textAlign={"center"}
						>
							เจ้าของธุรกิจหรือแอดมิน
						</Box>
						<OrderedList
							spacing={6}
							py={{ base: 2, md: 4 }}
							px={{ base: 2, md: 8 }}
						>
							<ListItem>เพิ่มหมวดหมู่สินค้าหรือบริการ</ListItem>
							<ListItem>เพิ่มสินค้าหรือบริการ</ListItem>
							<ListItem>
								เพิ่มรายชื่อ{bType === "hotel" ? "ห้อง" : "โต๊ะ"}
							</ListItem>
							<ListItem>
								ปรับแต่งข้อมูลรายละเอียด{bType === "hotel" ? "ที่พัก" : "ร้าน"}
							</ListItem>
							<ListItem>
								ดาวน์โหลด QR Menu เพื่อนำไปติดตั้งตาม
								{bType === "hotel" ? "ห้อง" : "โต๊ะ"}ต่างๆ
							</ListItem>
						</OrderedList>

						<Box
							mt={4}
							p={4}
							bgColor={"cyan.700"}
							color={"gray.200"}
							fontWeight={"bold"}
							textAlign={"center"}
						>
							พนักงาน
						</Box>
						<OrderedList
							spacing={6}
							py={{ base: 2, md: 4 }}
							px={{ base: 2, md: 8 }}
						>
							<ListItem>
								โหลดไฟล์ APK พร้อมติดตั้งบนมือถือหรือแท็บเล็ตแอนดรอยด์
							</ListItem>
							<ListItem>ลงชื่อเข้าใช้งาน</ListItem>
							<ListItem>
								<Text mb={2}>ฟังก์ชั่นการทำงาน</Text>
								<UnorderedList>
									<SimpleGrid
										columns={{ base: 1, md: 1, lg: 2, xl: 3 }}
										spacingY={3}
										spacingX={5}
									>
										<ListItem>แจ้งเตือนออเดอร์</ListItem>
										<ListItem>แสดงรายการสั่งซื้อ</ListItem>
										<ListItem>
											เพิ่มออเดอร์ในกรณีที่ลูกค้าไม่สะดวกสั่งเอง
										</ListItem>
										<ListItem>เช็คยอดสั่งซื้อรวม</ListItem>
										<ListItem>
											เคลียร์{bType === "hotel" ? "ห้อง" : "โต๊ะ"}
											เพื่อรองรับลูกค้าใหม่
										</ListItem>
									</SimpleGrid>
								</UnorderedList>
							</ListItem>
						</OrderedList>
						<AccordionItem mt={2} border={"none"} display={"none"}>
							<AccordionButton p={0} _hover={"none"}>
								<Flex
									w={"25em"}
									p={{ base: 1, md: 2 }}
									bgColor={bgColor}
									borderRadius={"full"}
								>
									<Text
										ml={3}
										fontWeight={"400"}
										fontSize={"large"}
										color={"white"}
									>
										ตัวอย่างการใช้งานของพนักงาน
									</Text>
									<Spacer />
									<AccordionIcon color={"white"} mt={1} />
								</Flex>
							</AccordionButton>
							<AccordionPanel>
								<Box mt={1}>
									<EmployeeTest />
								</Box>
							</AccordionPanel>
						</AccordionItem>
					</Accordion>
				</Card>

				<Flex mt={5}>
					<Text>หากมีข้อสงสัยติดต่อสอบถามเพิ่มเติมได้ที่ </Text>
					<Spacer />
					<Box gap={4} textAlign={"right"}>
						<Text color={"gray.600"}>sevscan.info@gmail.com </Text>
						<Text color={"gray.600"}>LINE : @sevscan</Text>
					</Box>
				</Flex>
			</Container>
		</>
	);
}

export default Manual;
