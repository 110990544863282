import React, { useState, useMemo } from "react";
import { SimpleGrid, Box, Image, Container, Spinner } from "@chakra-ui/react";
import Image1 from "../images/ex_menu/3.jpg";
import Image2 from "../images/ex_menu/5.jpg";
import Image3 from "../images/ex_menu/7.jpg";
import Image4 from "../images/ex_menu/10.jpg";
import Image5 from "../images/ex_menu/12.jpg";

import Design1 from "../images/ex_menu/design_menu/1.jpg";
import Design4 from "../images/ex_menu/design_menu/4.jpg";
import Design5 from "../images/ex_menu/design_menu/5.jpg";
import Design9 from "../images/ex_menu/design_menu/9.jpg";
import Design10 from "../images/ex_menu/design_menu/10.jpg";

function QRExample() {
	const images = useMemo(
		() => [
			Image1,
			Image4,
			Image5,
			Image2,
			Image3,
			Design1,
			Design10,
			Design4,
			Design5,
			Design9,
		],
		[]
	);

	return (
		<>
			<Box minH="calc(100vh - 200px)">
				<Box textAlign={"center"} py={20}>
					<Container maxW="container.xl">
						<SimpleGrid
							columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
							spacing={{ base: 5, md: 6, lg: 7, xl: 8 }}
						>
							{images.map((image, index) => (
								<Box key={index}>
									<ImageCard src={image} />
								</Box>
							))}
						</SimpleGrid>
					</Container>
				</Box>
			</Box>
		</>
	);
}

const ImageCard = ({ src }) => {
	const [isLoading, setIsLoading] = useState(true);

	const handleImageLoad = () => {
		setIsLoading(false);
	};

	return (
		<Box maxW="sm" rounded="lg">
			<Box position="relative" minH={"220px"}>
				{isLoading && (
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						position="absolute"
						top="0"
						left="0"
						width="100%"
						height="100%"
						bg="rgba(255, 255, 255, 0.8)"
						rounded="lg"
					>
						<Spinner size="sm" />
					</Box>
				)}
				<Image src={src} rounded="lg" onLoad={handleImageLoad} />
			</Box>
		</Box>
	);
};

export default QRExample;
