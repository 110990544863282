import React from "react";
import { useNavigate } from "react-router-dom";
import {
	Box,
	Flex,
	Heading,
	Text,
	Stack,
	Container,
	useColorModeValue,
	chakra,
	Image,
	Icon,
	SimpleGrid,
} from "@chakra-ui/react";
import { FaHotel } from "react-icons/fa";
import { ImTruck } from "react-icons/im";
import { IoRestaurant, IoStorefrontSharp } from "react-icons/io5";
import { MdHotel, MdCoffee } from "react-icons/md";
import { GiBeerStein, GiBowlOfRice } from "react-icons/gi";

import Img1 from "../../images/home_page/business/1.jpg";
import Img2 from "../../images/home_page/business/2.jpg";
import Img3 from "../../images/home_page/business/3.jpg";
import Img4 from "../../images/home_page/business/4.jpg";
import Img5 from "../../images/home_page/business/5.jpg";
import Img6 from "../../images/home_page/business/6.jpg";
import Img7 from "../../images/home_page/business/7.jpg";
import Img8 from "../../images/home_page/business/8.jpg";

const businesses = [
	{
		src: Img1,
		icon: IoRestaurant,
		name: "ร้านอาหาร",
		link: "/restaurant",
	},
	{
		src: Img2,
		icon: GiBeerStein,
		name: "ลานเบียร์",
		link: "/restaurant",
	},
	{
		src: Img3,
		icon: MdCoffee,
		name: "ร้านกาแฟ",
		link: "/restaurant",
	},
	{
		src: Img4,
		icon: GiBowlOfRice,
		name: "ร้านตามสั่ง",
		link: "/accommodation",
	},
	{
		src: Img5,
		icon: ImTruck,
		name: "ฟู้ดทรัค",
		link: "/accommodation",
	},
	{
		src: Img6,
		icon: MdHotel,
		name: "รีสอร์ท",
		link: "/accommodation",
	},
	{
		src: Img7,
		icon: FaHotel,
		name: "โรงแรม",
		link: "/restaurant",
	},
	{
		src: Img8,
		icon: IoStorefrontSharp,
		name: "ร้านค้าในหอพัก",
		link: "/accommodation",
	},
];

const BusinessCard = ({ business, navigate }) => (
	<Box
		w={"full"}
		mx="auto"
		bg="white"
		_dark={{ bg: "gray.800" }}
		shadow="lg"
		rounded="lg"
		overflow="hidden"
		// cursor="pointer"
		_hover={{ boxShadow: "0 0 20px #00000056" }}
		// onClick={() => navigate(business.link)}
	>
		<Stack direction={{ base: "row", md: "column" }} spacing={0}>
			<Image
				w={{ base: 20, md: "full" }}
				h={{ base: 20, md: 56 }}
				objectFit="cover"
				objectPosition="center"
				src={business.src}
				alt="avatar"
			/>
			<Flex
				alignItems="center"
				justifyContent={{ base: "left", md: "center" }}
				px={{ base: 3, md: 4 }}
				py={2}
				bg="gray.900"
				w={"full"}
			>
				<Icon as={business.icon} color="white" />
				<chakra.h2 mx={3} color="white" fontWeight="bold" fontSize="sm">
					{business.name}
				</chakra.h2>
			</Flex>
		</Stack>
	</Box>
);

const BusinessList = () => {
	const navigate = useNavigate();

	return (
		<Box
			bg={useColorModeValue("white", "gray.700")}
			py={{ base: 3, md: 8, lg: 12, xl: 16 }}
			mt={10}
		>
			<Container maxW={"7xl"} as={Stack} spacing={12}>
				<Stack spacing={0} align={"center"} textAlign={"center"}>
					<Heading>ตัวอย่างธุรกิจ</Heading>
					<Text pt={1}>
						เราได้ปรับแต่งโปรแกรมให้ตอบโจทย์ความต้องการของลูกค้าแต่ละประเภทให้ได้มากที่สุด
					</Text>
				</Stack>
				<SimpleGrid
					columns={{ base: 1, md: 3, lg: 4, xl: 4 }}
					spacing={{ base: 2, md: 6, lg: 8, xl: 10 }}
				>
					{businesses.map((business, index) => (
						<BusinessCard key={index} business={business} navigate={navigate} />
					))}
				</SimpleGrid>
			</Container>
		</Box>
	);
};

export default BusinessList;
