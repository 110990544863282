import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../config/apiConfig";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	Button,
	useDisclosure,
	Box,
	Input,
	useToast,
	Img,
	InputGroup,
	InputRightElement,
} from "@chakra-ui/react";
import { FaRightLong } from "react-icons/fa6";
import MenuList from "./MenuList";
import bgimage from "../images/logo.png";
import bgImg from "../images/bg_monitor.jpg";

function Customer() {
	const params = useParams();
	const toast = useToast();
	const inputRef = useRef(null);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [inputValue, setInputValue] = useState();

	const [shopIdentifire, setShopIdentifire] = useState();
	const [isCheckCode, setIsCheckCode] = useState(false);
	const [displayContent, setDisplayContent] = useState();

	useEffect(() => {
		onOpen();
		setShopIdentifire(params.shopname);

		const old_code = localStorage.getItem("shop_code")
			? JSON.parse(localStorage.getItem("shop_code"))[params.shopname] || ""
			: "";
		setInputValue(old_code);
	}, [params, onOpen]);

	const handleChange = (event) => {
		setInputValue(event.target.value.trim());
	};

	const handleCheckCode = async (e) => {
		// console.log(shopIdentifire, inputValue);
		if (inputValue === "") {
			toast({
				title: `กรุณากรอกโค๊ต`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			inputRef.current.focus();
		} else {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/shopping/check_code`,
					{
						shopIdentifire,
						inputValue,
					}
				);
				console.log(response.data);
				const res = response.data;
				if (res.message === "success") {
					if (response.data.unitShopResult.is_delete === "1") {
						toast({
							title: `โค๊ตนี้ไม่ได้ใช้งานแล้ว`,
							position: "top",
							isClosable: true,
							status: "warning",
							variant: "top-accent",
							duration: 5000,
						});
					} else {
						setDisplayContent(res);
						onClose();
						setIsCheckCode(true);

						let existingShopCode = {};
						if (localStorage.getItem("shop_code") !== null) {
							existingShopCode = JSON.parse(localStorage.getItem("shop_code"));
						}
						const updatedShopCode = {
							...existingShopCode,
							[shopIdentifire]: inputValue.toUpperCase(),
						};
						localStorage.setItem("shop_code", JSON.stringify(updatedShopCode));
					}
				} else {
					toast({
						title: `ไม่มีโค๊ตนี้ในร้าน`,
						position: "top",
						isClosable: true,
						status: "warning",
						variant: "top-accent",
						duration: 5000,
					});
				}
			} catch (error) {
				toast({
					title: `ไม่สามารถติดต่อระบบได้`,
					description: `กรุณาติดต่อพนักงาน`,
					position: "top",
					isClosable: true,
					status: "error",
					variant: "top-accent",
					duration: 5000,
				});
			}
		}
	};

	const [logo, setLogo] = useState();
	const [shopName, setShopName] = useState();
	const [linkError, setLinkError] = useState(false);
	const [token, setToken] = useState();
	const [checkLastDate, setCheckLastDate] = useState();
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/shopping/shop_mobile_home`,
					{ shopIdentifire }
				);
				// console.log("res", response.data);
				if (response.data.results.length === 0) {
					setLinkError(true);
				} else {
					if (response.data.results[0].logo !== "") {
						setLogo(response.data.results[0].logo);
					} else {
						setLogo("default.png");
					}
					setShopName(response.data.results[0].name);
					setToken(response.data.results[0].token);
					setCheckLastDate(response.data.results[0].last_day);
				}
			} catch (error) {
				console.error("Error list_shop:", error);
			}
		};
		if (shopIdentifire !== undefined) {
			fetchData();
		}
	}, [shopIdentifire]);

	const handleSiteHomePage = () => {
		window.open("https://www.sevscan.com", "_blank");
	};

	return (
		<>
			<Modal
				closeOnOverlayClick={false}
				isOpen={isOpen}
				onClose={onClose}
				size={"full"}
				isCentered
			>
				<ModalOverlay />
				<ModalContent
					textAlign="center"
					display="flex"
					alignItems="center"
					justifyContent="center"
					position="relative"
					height="100vh"
					style={{
						backgroundImage: `url(${bgImg})`,
						// backgroundColor: "#1b788a",
						// backgroundSize: "cover",
						backgroundPosition: "center",
						zIndex: "0",
						// filter: "hue-rotate(90deg)",
					}}
				>
					{linkError ? (
						<>
							<Img
								src={bgimage}
								maxW={"70%"}
								maxH={"60%"}
								mb={{ base: "10vh", sm: "1vh" }}
							/>
							<Box
								mb={{ base: "2vh", sm: "1vh" }}
								fontSize={"xl"}
								w={"full"}
								py={1}
								fontWeight={"bold"}
								bg={"#7c000021"}
								color={"gray.200"}
								minH={10}
							>
								QR Menu ร้านไม่ถูกต้อง
							</Box>
						</>
					) : (
						<>
							<Box
								position="absolute"
								top="0"
								left="0"
								right="0"
								bottom="0"
								zIndex="-1"
								backdropFilter="blur(1px)"
							/>
							{logo && (
								<Img
									src={`${API_BASE_URL}/image/logo/${logo}`}
									maxW={"70%"}
									maxH={"60%"}
									mb={{ base: "10vh", sm: "1vh" }}
								/>
							)}
							<Box
								mb={{ base: "2vh", sm: "1vh" }}
								fontSize={"xl"}
								w={"full"}
								py={1}
								px={2}
								fontWeight={"bold"}
								// bg={"cyan.700"}
								color={"gray.600"}
								fontFamily={"mali"}
								minH={10}
							>
								{token === 0 ? "เพิ่ม LINE Notify Token ก่อนใช้งาน" : shopName}
							</Box>
							{checkLastDate === 1 && (
								<Box textColor={"red"}>หมดอายุการใช้งานแล้ว</Box>
							)}
							{token !== 0 && checkLastDate === 0 ? (
								<Box mb={3} display="flex" justifyContent="center">
									<InputGroup w="50%">
										<Input
											ref={inputRef}
											onChange={handleChange}
											value={inputValue}
											maxLength={4}
											focusBorderColor="white"
											type="text"
											fontSize="2em"
											fontWeight={"bold"}
											border={"4px solid white"}
											bgColor={"gray.100"}
											borderRadius={"full"}
											color={"cyan.700"}
											p="0.8em"
											sx={{ textTransform: "uppercase" }}
											onKeyPress={(e) => {
												if (e.key === "Enter") {
													handleCheckCode();
												}
											}}
										/>
										<InputRightElement width="4.5rem" mt={"0.6em"}>
											<Button
												borderRadius={"full"}
												bgColor={"white"}
												onClick={handleCheckCode}
												focusBorderColor="white"
											>
												<FaRightLong color={"gray"} size={"1.5em"} />
											</Button>
										</InputRightElement>
									</InputGroup>
								</Box>
							) : (
								""
							)}
						</>
					)}

					<Box
						position={"absolute"}
						bottom={{ base: "5vh", sm: "1.5vh", md: "2vh" }}
						// bgColor={"#6f6f6f21"}
						borderRadius={"1em"}
						color={"gray.600"}
						fontWeight={"400"}
						px={10}
						pb={1}
						onClick={handleSiteHomePage}
					>
						www.sevscan.com
					</Box>
				</ModalContent>
			</Modal>

			{isCheckCode && <MenuList data={displayContent} />}
		</>
	);
}

export default Customer;
