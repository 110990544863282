import { useNavigate } from "react-router-dom";
import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	Checkbox,
	Stack,
	Button,
	Heading,
	Text,
	useColorModeValue,
	useToast, // Import useToast
} from "@chakra-ui/react";
import { useState, useContext } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../config/apiConfig";
import TeamContext from "../../hooks/TeamContext";

export default function Login() {
	const navigate = useNavigate();
	const toast = useToast(); // Initialize useToast
	const { setTeamUser } = useContext(TeamContext);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const handleSubmit = async () => {
		if (!email.trim() || !password.trim()) {
			toast({
				title: "กรุณากรอกข้อมูลให้ครบถ้วน",
				status: "warning",
				duration: 3000,
				isClosable: true,
			});
			return;
		}

		// Check email format
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(email)) {
			toast({
				title: "รูปแบบอีเมลไม่ถูกต้อง",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
			return;
		}

		try {
			const response = await axios.post(`${API_BASE_URL}/team/login`, {
				email: email.trim(),
				password: password.trim(),
			});

			// Handle successful login
			if (response.data.message === "success") {
				localStorage.setItem(
					"team_data",
					JSON.stringify(response.data.userTeamData)
				);
				setTeamUser(response.data.userTeamData);
				navigate("/team/profile");
			} else {
				toast({
					title: response.data.message,
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			}
		} catch (err) {
			console.error("Login error:", err);
			toast({
				title: "เกิดข้อผิดพลาดในการลงชื่อเข้าใช้",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		}
	};

	return (
		<Flex align="center" justify="center" minH="calc(100vh - 96px)">
			<Stack w={{ base: "full", sm: "auto" }} py={12}>
				<Box
					rounded="lg"
					bg={useColorModeValue("white", "gray.700")}
					p={8}
					w={{
						base: "full",
						sm: "480px",
						md: "480px",
					}}
				>
					<Stack align="center" pb={8}>
						<Heading fontSize="4xl">ลงชื่อเข้าใช้</Heading>
					</Stack>
					<Box>
						<Stack spacing={4}>
							<FormControl id="email" isRequired>
								<FormLabel>อีเมล</FormLabel>
								<Input
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</FormControl>
							<FormControl id="password" isRequired>
								<FormLabel>รหัสผ่าน</FormLabel>
								<Input
									type="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</FormControl>
							<Stack spacing={10}>
								<Stack
									direction={{ base: "column", sm: "row" }}
									align="start"
									justify="space-between"
									display={"none"}
								>
									<Checkbox>Remember me</Checkbox>
									<Text color="blue.400">Forgot password?</Text>
								</Stack>
								<Button
									bg="blue.400"
									color="white"
									type="submit"
									_hover={{
										bg: "blue.500",
									}}
									onClick={handleSubmit}
								>
									ลงชื่อ
								</Button>
							</Stack>
						</Stack>
					</Box>
				</Box>
			</Stack>
		</Flex>
	);
}
