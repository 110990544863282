import React from "react";
import TopTeamMenuBar from "../team/TopTeamMenuBar";
import { TeamProvider } from "../hooks/TeamContext";

const PrivateRoute = () => {
	return (
		<>
			<TeamProvider>
				<TopTeamMenuBar />
			</TeamProvider>
		</>
	);
};

export default PrivateRoute;
