import React, { useMemo, useEffect, useState, useContext } from "react";
import axios from "axios";
import {
	Box,
	useToast,
	Button,
	FormControl,
	Input,
	Checkbox,
	Flex,
	useColorModeValue,
	Text,
	Stack,
	Textarea,
} from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";
import { API_BASE_URL } from "../../config/apiConfig";
import { useAuth } from "../../hooks/AuthProvider";
import ShopContext from "../../hooks/ShopContext";
import ImageShopUploader from "../component/ImageShopUploader";

function Shop() {
	const { shopValueId } = useContext(ShopContext);
	const toast = useToast();
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);

	const [shopCode, setShopCode] = useState();
	const [shopName, setShopName] = useState();
	const [shopNameEn, setShopNameEn] = useState("");
	const [shopRecommend, setShopRecommend] = useState("");
	const [shopRecommendEn, setShopRecommendEn] = useState("");
	const [checkedCallStaff, setCheckedCallStaff] = useState();
	const [shopLogo, setShopLogo] = useState();
	useEffect(() => {
		// console.log(shopValueId);
		const fetchData = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/shop_detail`,
					{ shopValueId },
					{ headers }
				);

				// console.log("shop_detail", response.data);
				setShopCode(response.data.results[0].code);
				setShopName(response.data.results[0].name);
				setShopNameEn(response.data.results[0].name_en);
				setShopRecommend(response.data.results[0].recommend);
				setShopRecommendEn(response.data.results[0].recommend_en);
				setCheckedCallStaff(
					response.data.results[0].show_call_staff === 1 ? true : false
				);
				if (response.data.results[0].logo !== "") {
					setShopLogo(response.data.results[0].logo);
				} else {
					setShopLogo("default.png");
				}
			} catch (error) {
				console.error("Error qr_detail and list_unit:", error);
			}
		};

		if (shopValueId && headers) {
			fetchData();
		}
	}, [toast, headers, shopValueId]);

	const [editLogo, setEditLogo] = useState(false);
	const [isLoadingSave, setIsLoadingSave] = useState(false);
	useEffect(() => {
		const updateShopDetail = async () => {
			// console.log(shopLogo, shopName, checkedCallStaff, shopDetail);
			const trimmedName = shopName.trim();
			const trimmedNameEn = shopNameEn;
			const trimmedRecommend = shopRecommend;
			const trimmedRecommendEn = shopRecommendEn;

			if (!trimmedName) {
				toast({
					title: `กรอกชื่อร้านก่อนบันทึก`,
					position: "top",
					isClosable: true,
					status: "warning",
					variant: "top-accent",
					duration: 5000,
				});
				return;
			}

			setIsLoadingSave(true);
			try {
				const formData = new FormData();
				formData.append("name", trimmedName);
				formData.append("name_en", trimmedNameEn);
				formData.append("recommend", trimmedRecommend);
				formData.append("recommend_en", trimmedRecommendEn);
				formData.append("checkedCallStaff", checkedCallStaff);
				formData.append("shopValueId", shopValueId);
				if (shopLogo) {
					formData.append("logo", shopLogo);
				}

				const response = await axios.post(
					`${API_BASE_URL}/api/site/shop_update`,
					formData,
					{ headers }
				);
				if (response.data.message === "success") {
					toast({
						title: `อัพเดทเรียบร้อย`,
						position: "top",
						isClosable: true,
						status: "success",
						variant: "top-accent",
						duration: 5000,
					});
					setIsLoadingSave(false);
					setEditLogo(false);
				} else {
					console.error("Error shop detial/update");
				}
			} catch (error) {
				console.error("Error saving shop detail:", error);
			}
		};

		if (editLogo) {
			updateShopDetail();
		}
	}, [
		shopLogo,
		editLogo,
		setEditLogo,
		checkedCallStaff,
		headers,
		shopName,
		shopNameEn,
		shopRecommend,
		shopRecommendEn,
		shopValueId,
		toast,
	]);

	const handleChange = () => {
		setCheckedCallStaff(!checkedCallStaff);
	};

	return (
		<>
			<Flex direction={{ base: "column", lg: "row" }} gap={5}>
				<Box flex={{ lg: 8, xl: 9 }}>
					<Box
						bgColor={useColorModeValue("white", "gray.600")}
						borderRadius={"1em"}
						p={{ base: 5, md: 10 }}
					>
						<Stack
							direction={{ base: "column", md: "row" }}
							as={"form"}
							spacing={2}
							mb={7}
						>
							<FormControl w={{ base: "100%", md: "40%", lg: "30%" }}>
								<Text fontWeight={"bold"} mt={2} color={"cyan.700"}>
									โลโก้
								</Text>
							</FormControl>
							<FormControl>
								<Box w={"200px"}>
									<ImageShopUploader
										shopLogo={shopLogo}
										setShopLogo={setShopLogo}
									/>
								</Box>
							</FormControl>
						</Stack>
						<Stack
							direction={{ base: "column", md: "row" }}
							as={"form"}
							spacing={2}
							mb={6}
						>
							<FormControl w={{ base: "100%", md: "40%", lg: "30%" }}>
								<Text fontWeight={"bold"} mt={2} color={"cyan.700"}>
									ลิ้งค์ตัวอย่าง
								</Text>
							</FormControl>
							<FormControl>
								<Flex>
									<Text pt={2}>https://sevscan.com/demo/</Text>
									<Text pt={2} textColor={"gray.600"} fontWeight={"900"}>
										{shopCode}
									</Text>
								</Flex>
							</FormControl>
						</Stack>
						<Stack
							direction={{ base: "column", md: "row" }}
							as={"form"}
							spacing={2}
							mb={6}
						>
							<FormControl w={{ base: "100%", md: "40%", lg: "30%" }}>
								<Text fontWeight={"bold"} mt={2} color={"cyan.700"}>
									ชื่อร้านหรือกิจการ
								</Text>
							</FormControl>
							<FormControl>
								<Flex
									gap={{ base: 0, xl: 3 }}
									direction={{
										base: "column",
										xl: "row",
									}}
								>
									<Input
										mb={2}
										type="text"
										placeholder="ภาษาไทย"
										value={shopName}
										onChange={(e) => setShopName(e.target.value)}
									/>
									<Input
										type="text"
										placeholder="ภาษาอังกฤษ (ถ้ามี)"
										value={shopNameEn}
										onChange={(e) => setShopNameEn(e.target.value)}
									/>
								</Flex>
							</FormControl>
						</Stack>
						<Stack
							direction={{ base: "column", md: "row" }}
							as={"form"}
							spacing={2}
							mb={7}
						>
							<FormControl w={{ base: "100%", md: "40%", lg: "30%" }}>
								<Text fontWeight={"bold"} mt={2} color={"cyan.700"}>
									ป้ายแนะนำลูกค้า (ถ้ามี)
								</Text>
							</FormControl>
							<FormControl>
								<Flex
									gap={{ base: 0, xl: 3 }}
									direction={{
										base: "column",
										xl: "row",
									}}
								>
									<Textarea
										mb={2}
										placeholder={`เช่น ส่งสินค้า 2 รอบ 12:00 และ 17:00 น. \n ${"     "} รับออเดอร์ตั้งแต่ 6.00 - 22.00 น.`}
										value={shopRecommend}
										onChange={(e) => setShopRecommend(e.target.value)}
									/>
									<Textarea
										mb={2}
										placeholder={`e.g. Delivery times: 12:00 PM and 5:00 PM \n ${"     "} Order hours: 6:00 AM to 10:00 PM`}
										value={shopRecommendEn}
										onChange={(e) => setShopRecommendEn(e.target.value)}
									/>
								</Flex>
								<Text
									py={2}
									px={2}
									fontSize={"sm"}
									color={"red.400"}
									bgColor={"gray.50"}
								>
									หากยังไม่มีภาษาอังกฤษ
									และลูกค้าของทางร้านเปลี่ยนใช้ภาษาอังกฤษระบบจะแสดงเป็นภาษาไทยให้ก่อน
								</Text>
							</FormControl>
						</Stack>
						<Stack
							direction={{ base: "column", md: "row" }}
							as={"form"}
							spacing={2}
							mb={7}
						>
							<FormControl w={{ base: "100%", md: "40%", lg: "30%" }}>
								<Text fontWeight={"bold"} color={"cyan.700"}>
									ปุ่มเรียกพนักงาน
								</Text>
							</FormControl>
							<FormControl>
								<Checkbox isChecked={checkedCallStaff} onChange={handleChange}>
									แสดง(หน้าเลือกซื้อสินค้าของลูกค้า)
								</Checkbox>
							</FormControl>
						</Stack>
						<Box textAlign={"right"} pt={2}>
							<Button
								w={"15em"}
								size={{ base: "sm", md: "md" }}
								borderRadius={"full"}
								border={"1.5px solid"}
								borderColor={"cyan.700"}
								color={"cyan.800"}
								bgColor={"#d0e7ec"}
								isLoading={isLoadingSave}
								onClick={() => setEditLogo(true)}
								spinner={<BeatLoader size={8} color="#0987a0" />}
							>
								บันทึก
							</Button>
						</Box>
					</Box>
				</Box>
			</Flex>
		</>
	);
}

export default Shop;
