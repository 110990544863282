import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import bgImage from "../images/bg_monitor.jpg";

function SendReScan() {
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			height="100vh"
			width="100vw"
			overflow="hidden"
			position="relative"
			bgImage={bgImage}
		>
			<Flex direction={"column"} textAlign={"center"} alignItems="center">
				<Box fontWeight={"600"} mt={4} color={"cyan.800"}>
					สแกน QR Menu ใหม่
				</Box>
			</Flex>
		</Box>
	);
}

export default SendReScan;
